// Core
import React from "react";

// UI
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import dayjs from "utils/dateUtils";

// Styles
import { StyledSup } from "./style";

export const INITIAL_VALUE = {
    week: dayjs().customWeek(),
    year: dayjs().endOf("isoWeek").year(),
};

const CURRENT_YEAR = dayjs().year();

function getLastWeeksOptions(count) {
    const result = [];

    const start = dayjs()
        .subtract(count, "week")
        .startOf("isoWeek")
        .set("hors", 0)
        .set("minutes", 0);
    const end = dayjs().set("hors", 0).set("minutes", 1);

    let step = dayjs(start);

    while (!step.isAfter(end)) {
        result.push({
            week: step.customWeek(),
            year: dayjs(step).endOf("isoWeek").year(),
        });
        step = step.add(1, "week");
    }

    return result;
}

const WEEK_OPTIONS = getLastWeeksOptions(12).reverse();

function WeekSelector({ setWeek, selectedWeek, setDay }) {
    const onChangeWeeks = (e, value) => {
        setWeek(value);
        setDay(null);
    };

    const getOptionSelected = (option, value) => {
        return option.week === value.week && option.year === value.year;
    };

    const getOptionLabel = option => {
        return option.week
            ? `${option.week}${
                  CURRENT_YEAR !== option.year ? "," + option.year : ""
              }`
            : "";
    };

    return (
        <Autocomplete
            value={selectedWeek}
            options={WEEK_OPTIONS}
            onChange={onChangeWeeks}
            filterSelectedOptions
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderOption={option => (
                <p>
                    {option.week}
                    {CURRENT_YEAR !== option.year && (
                        <StyledSup>{option.year}</StyledSup>
                    )}
                </p>
            )}
            renderInput={params => (
                <TextField {...params} label="Week number" />
            )}
        />
    );
}

export default WeekSelector;
