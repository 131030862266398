import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// ui
import Time from "../Time";

const EmptyValue = () => <Typography variant="subtitle1">0</Typography>;

const Caption = ({ title }) => (
    <Box mr={1}>
        <Typography variant="overline" color="textSecondary">
            <b>{title}</b>
        </Typography>
    </Box>
);

function TotalTime({ totalTime, title }) {
    if (!totalTime) {
        return (
            <>
                <Caption title={title} />
                <EmptyValue />
            </>
        );
    }

    return (
        <>
            <Caption title={title} />
            {Object.entries(totalTime).map(([key, value], i) => {
                if (key === "decimal") return null;
                return <Time value={value} unit={key} key={`${i}_${value}`} />;
            })}
            <Box component={Typography} variant="subtitle1" px={1}>
                {"/"}
            </Box>
            <Time value={`${totalTime.decimal}`} unit="h" />
        </>
    );
}

export default TotalTime;
