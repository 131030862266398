// Core
import React, { useCallback } from "react";
import throttle from "lodash.throttle";

// Styles
import { Wrapper, ListItems, CustomPickerInput, CustomPicker } from "./styles";

// Icons
import paint from "./paint.svg";

const defaultColors = [
    null,
    "#2196F3",
    "#f44336",
    "#9C27B0",
    "#3F51B5",
    "#4CAF50",
    "#FFEB3B",
    "#FFC107",
    "#FF5722",
    "#795548",
];

const ColorPicker = ({
    colors = [...defaultColors],
    onChange,
    activeColor,
}) => {
    const onChangeThrottle = throttle(val => {
        onChange(val);
    }, 200);

    const onChangeColor = useCallback(
        e => {
            onChangeThrottle(e.currentTarget.value);
        },
        [onChangeThrottle]
    );

    let customColors = colors.concat([]);

    if (typeof activeColor === "string" && !colors.includes(activeColor)) {
        customColors.pop();

        customColors.push(activeColor);
    }

    return (
        <Wrapper>
            {customColors.map(color => (
                <ListItems
                    isActive={activeColor === color}
                    key={color}
                    color={color}
                    onClick={() => onChange(color)}
                />
            ))}
            <CustomPicker>
                <label htmlFor="colorPicker">
                    <img
                        src={paint}
                        width={30}
                        height={30}
                        alt="color picker icon"
                    />
                </label>
                <CustomPickerInput
                    id="colorPicker"
                    type="color"
                    value={activeColor || "#000"}
                    onChange={onChangeColor}
                />
            </CustomPicker>
        </Wrapper>
    );
};

export default ColorPicker;
