// Core
import React from "react";
import { useObserver } from "mobx-react";

// UI
import { Container, Box, Typography } from "@material-ui/core";
import ReportHistoryTable from "./ReportHistoryTable";
import ReportPagination from "components/ReportPagination";
import StyledPaper from "components/StyledPaper";

// store
import { useStores } from "store";
function useStore() {
    const { exportStore, paginationStore } = useStores();

    return useObserver(() => ({
        historyItems: exportStore.historyItems,
        onGetHistoryItems: exportStore.onGetHistoryItems,
        projectList: exportStore.projectList,
        onGetProjectList: exportStore.onGetProjectList,
        userList: exportStore.userList,
        onGetUserList: exportStore.onGetUserList,
        page: paginationStore.page,
        perPage: paginationStore.perPage,
        onDownload: exportStore.onDownload,
        onDeleteHistoryItem: exportStore.onDeleteHistoryItem,
    }));
}

export default function ReportHistory() {
    const {
        onGetHistoryItems,
        historyItems,
        onGetProjectList,
        projectList,
        onGetUserList,
        userList,
        page,
        perPage,
        onDownload,
        onDeleteHistoryItem,
    } = useStore();

    React.useEffect(() => {
        onGetProjectList();
        onGetUserList();
    }, [onGetProjectList, onGetUserList]);

    React.useEffect(() => {
        onGetHistoryItems({
            page,
            per_page: perPage,
        });
    }, [onGetHistoryItems, page, perPage]);

    const deleteHistoryItem = async id => {
        await onDeleteHistoryItem(id);
        onGetHistoryItems({
            page,
            per_page: perPage,
        });
    };
    return (
        <Container>
            <Box my={2}>
                <Typography variant="h6">Export history</Typography>
            </Box>
            <Box my={3}>
                <StyledPaper>
                    <ReportHistoryTable
                        userList={userList}
                        projectList={projectList}
                        historyItems={historyItems}
                        onDownload={onDownload}
                        deleteHistoryItem={deleteHistoryItem}
                    />
                    {historyItems?.length > 0 && <ReportPagination />}
                </StyledPaper>
            </Box>
        </Container>
    );
}
