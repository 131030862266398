// Core
import React from "react";
import styled from "styled-components";
import { grey } from "@material-ui/core/colors";
// Styles
import TableCell from "@material-ui/core/TableCell";

export const StyledTableCell = styled(({ noWrap, minWidth, ...restProps }) => (
    <TableCell {...restProps} />
))`
    border-right: 1px solid ${grey[300]};
    min-width: ${({ minWidth }) => minWidth};
    width: ${({ width }) => (width ? `${width}` : "auto")};
    white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
`;
