import React, { Fragment } from "react";
import { Typography, Divider } from "@material-ui/core";
import ActivityBlock from "../ActivityBlock";
import dayjs from "utils/dateUtils";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

import GetAppIcon from "@material-ui/icons/GetApp";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SettingsIcon from "@material-ui/icons/Settings";
import { FULL_DATE_FORMAT, FULL_DATE_SERVER } from "constants/dateFormats";

function ActivitiesList({ activities, date }) {
    const getIcon = type => {
        switch (true) {
            case type.includes("user"):
                return <PermIdentityIcon />;
            case type.includes("export"):
                return <GetAppIcon />;
            case type.includes("project"):
                return <BusinessCenterIcon />;
            case type.includes("system"):
                return <SettingsIcon />;
            default:
                return null;
        }
    };

    return (
        <Timeline align="alternate">
            <Typography
                variant="h5"
                align="center"
                display="block"
                gutterBottom
            >
                {dayjs(date, FULL_DATE_SERVER).format(FULL_DATE_FORMAT)}
            </Typography>
            {activities.map(type => (
                <Fragment key={`fragment-${type.title}`}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot>{getIcon(type.title)}</TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            {type.data.map((item, i) => {
                                return (
                                    <Fragment
                                        key={`activity-frag-${item.tree_id}-${i}`}
                                    >
                                        <Divider
                                            key={`activity-dot-${item.tree_id}-${i}`}
                                        />
                                        <ActivityBlock
                                            key={`activity-item-${item.tree_id}-${i}`}
                                            item={item}
                                        />
                                    </Fragment>
                                );
                            })}
                        </TimelineContent>
                    </TimelineItem>
                </Fragment>
            ))}
        </Timeline>
    );
}

export default ActivitiesList;
