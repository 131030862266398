// Core
import React, { useState, useEffect, useCallback } from "react";
import { useObserver } from "mobx-react";
// UI
import { Grid, Container, Typography, Box, Tabs, Tab } from "@material-ui/core";
import DevelopersTable from "./DevelopersTable";
import CreateUserForm from "./CreateUserForm";
import AddButton from "components/AddButton";
import ReportPagination from "components/ReportPagination";
import StyledPaper from "components/StyledPaper";

// Utils
import { useStores } from "../../store";

//Styles
import { StyledGrid } from "./styles";

const TABS = [
    { id: "active", title: "Active", Component: null },
    { id: "inactive", title: "Archived", Components: null },
];

function useStore() {
    const { userStore, paginationStore, logStore } = useStores();

    return useObserver(() => ({
        userList: userStore.userList,
        onGetUserList: userStore.onGetUserList,
        setEditingUserData: userStore.setEditingUserData,
        editingUserData: userStore.editingUserData,
        onCreateUser: userStore.onCreateUser,
        onUpdateUser: userStore.onUpdateUser,
        onDeleteUser: userStore.onDeleteUser,
        page: paginationStore.page,
        perPage: paginationStore.perPage,
        onGetProjectList: logStore.onGetProjectList,
        projectList: logStore.projectList,
        onResendInvitationUser: userStore.onResendInvitationUser,
        isResendingInvitation: userStore.isResendingInvitation,
    }));
}

export default function Projects() {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [sort, setSort] = useState({ field: "", type: "" });

    const {
        userList,
        onGetUserList,
        editingUserData,
        setEditingUserData,
        onCreateUser,
        onUpdateUser,
        onDeleteUser,
        page,
        perPage,
        onGetProjectList,
        projectList,
        isResendingInvitation,
        onResendInvitationUser,
    } = useStore();

    const showModal = () => {
        setModalVisibility(true);
    };

    const [selectedTab, setValue] = useState(0);
    const handleChange = (event, index) => {
        setValue(index);
    };

    const updateUserList = useCallback(() => {
        onGetUserList({
            page: page,
            per_page: perPage,
            sort: { [sort.field]: sort.type },
            filter: { status: TABS[selectedTab].id },
        });
    }, [onGetUserList, page, perPage, selectedTab, sort.field, sort.type]);

    useEffect(() => {
        updateUserList();
    }, [updateUserList]);

    const closeModal = () => {
        setModalVisibility(false);
        setEditingUserData();
        updateUserList();
    };

    const handleDeleteUser = async id => {
        await onDeleteUser(id);
        closeModal();
    };

    const handleResendInvitation = user => {
        onResendInvitationUser(user.id);
        if (user.status === "inactive")
            onUpdateUser({ ...user, status: "active" }, user.id);
    };

    useEffect(() => {
        onGetProjectList();
    }, [onGetProjectList]);

    useEffect(() => {
        if (editingUserData) {
            showModal();
        }
    }, [editingUserData]);

    const onSubmitUser = async (data, id) => {
        editingUserData
            ? await onUpdateUser(data, id)
            : await onCreateUser(data);
        await onGetProjectList();
        updateUserList();
        closeModal();
    };

    const onSort = field => () => {
        if (sort.type === "desc") {
            return setSort({ field: "", type: "" });
        }

        return setSort({ field, type: sort.field ? "desc" : "asc" });
    };

    return (
        <Container>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                centered
            >
                {TABS.map(({ id, title }) => (
                    <Tab key={id} label={title} />
                ))}
            </Tabs>
            <Grid container>
                <StyledGrid item xs={12}>
                    <Typography variant="h6">Team members:</Typography>
                </StyledGrid>
                <Grid item xs>
                    <AddButton handleClick={showModal}>
                        Add team member
                    </AddButton>
                </Grid>
                <Grid item xs={12}>
                    <Box pb={5}>
                        <StyledPaper>
                            <DevelopersTable
                                onSort={onSort}
                                sort={sort}
                                developerList={userList}
                                setEditingUserData={setEditingUserData}
                                projectList={projectList}
                            />
                            <ReportPagination />
                        </StyledPaper>
                    </Box>
                </Grid>
            </Grid>
            {userList.length && (
                <CreateUserForm
                    open={isModalVisible}
                    onClose={closeModal}
                    handleSubmit={onSubmitUser}
                    initialData={editingUserData}
                    handleDeleteUser={handleDeleteUser}
                    isResendingInvitation={isResendingInvitation}
                    onReinviteUser={handleResendInvitation}
                    projectList={projectList}
                />
            )}
        </Container>
    );
}
