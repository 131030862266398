// Core
import React from "react";
// UI
import { StyledTextField } from "../UserSelector/style";
import { Autocomplete } from "@material-ui/lab";
// Styles

export const WORK_ITEM_STATUSES = ["in review", "approved", "declined"];

function StatusSelector({ setFilter, selectedWorkItemStatus }) {
    const onChange = (e, value) => {
        setFilter("selectedWorkItemStatus", value);
    };

    const getOptionSelected = (option, value) => option === value;
    const getOptionLabel = option => `${option}`;

    return (
        <Autocomplete
            multiple
            value={selectedWorkItemStatus}
            options={WORK_ITEM_STATUSES}
            onChange={onChange}
            filterSelectedOptions
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderInput={params => (
                <StyledTextField {...params} label="Work item status" />
            )}
        />
    );
}

export default StatusSelector;
