// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class ProjectService {
    getProjectList = params => axios.get(`${API_URL}/project`, { params });
    getProjectTypes = () => axios.get(`${API_URL}/project/types`);
    getProjectUserList = id => axios.get(`${API_URL}/project/${id}/users`);
    createProject = body => axios.post(`${API_URL}/project`, { ...body });
    updateProject = (body, id) =>
        axios.put(`${API_URL}/project/${id}`, { ...body });
    deleteProject = id => axios.delete(`${API_URL}/project/${id}`);
    addUsersToProject = (body, id) =>
        axios.post(`${API_URL}/project/${id}/users`, { ...body });
    deleteUsersFromProject = (body, id) => {
        return axios.delete(`${API_URL}/project/${id}/users`, { data: body });
    };
}

const projectService = new ProjectService();

export default projectService;
