import styled from 'styled-components'
import backgroundImg from './img/404.png'

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: 10px;
    box-sizing: border-box;
`

export const PageContainer = styled.div`
    width: 870px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${backgroundImg}) no-repeat center center;
    background-size: 100%;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 380px;
`

export const MainText = styled.p`
    margin: 0;
    font-size: 23px;
    line-height: 35px;
    margin-bottom: 40px;
`

export const SecondaryText = styled.p`
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
`
