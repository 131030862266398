import styled from "styled-components";
import { Paper, Box } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
    overflow: scroll;
`;

export const ColoredStatus = styled(Box)(
    ({
        status,
        theme: {
            palette: {
                primary: { main },
                success: { main: mainSuccess },
                error: { main: mainError },
            },
        },
    }) => `
 color: ${
     status === "approved"
         ? mainSuccess
         : status === "declined"
         ? mainError
         : main
 }`
);
