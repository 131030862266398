import React from "react";
import styled from "styled-components";
import { ListItem } from "@material-ui/core";

export const StyledListItem = styled(({ active, ...restProps }) => (
    <ListItem {...restProps} />
))`
    justify-content: space-between;
    background-color: ${({ active, theme }) =>
        active ? theme?.palette?.info.light : "transparent"};
    cursor: pointer;
    &:hover {
        color: white;
        background-color: ${({ theme }) => theme?.palette?.info.light};
    }
`;
