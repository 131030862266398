// Core
import React from "react";
import styled from "styled-components";

// UI
import Card from "@material-ui/core/Card";
import { Typography, Box } from "@material-ui/core";
import TextAreaAutosize from "@material-ui/core/TextareaAutosize";

// Components
import ColoredName from "components/ColoredName";

export const StyledItemCard = styled(Card)`
    & .MuiAvatar-root {
        width: 60px;
        height: 60px;
    }
    & b {
        font-size: 14px;
    }
`;

export const StyledTextArea = styled(({ error, ...props }) => (
    <TextAreaAutosize {...props} />
))`
    padding: 18.5px 14px;
    border-radius: 8px;
    font: inherit;
    font-size: 16px;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    min-height: 110px;
    margin: 0;
    display: block;
    background: none;
    letter-spacing: inherit;
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.palette.error.main : "rgb(228 228 228)"};
    outline: none;
    resize: none;
    :focus {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
`;

export const StyledTypography = styled(Typography)`
    color: ${({ colortype = "#000" }) => colortype};
`;

export const StyledColoredName = styled(ColoredName)`
    display: inline-flex;
    vertical-align: text-bottom;
`;

export const Time = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 16px;
`;

export const StyledBox = styled(Box)`
    position: relative;
`;
