import styled from "styled-components";
import AddIcon from "@material-ui/icons/AddCircle";

export const StyledAddIcon = styled(AddIcon)`
    ${({ theme }) => `
    && {
        font-size: 40px;
    };
    color: ${theme.palette.info.main}
`}
`;
