// Core
import React from "react";
import { useObserver } from "mobx-react";

// UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";

// Store
import { useStores } from "../../store";

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

function useStore() {
    const store = useStores();

    return useObserver(() => ({
        notificationQueue: store.notificationStore.notificationQueue,
        hideNotification: store.notificationStore.hideNotification,
    }));
}

export default function Notification() {
    const { notificationQueue, hideNotification } = useStore();

    return notificationQueue.map(item => (
        <Snackbar
            open
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            autoHideDuration={item.duration}
            TransitionComponent={SlideTransition}
            onClose={() => hideNotification(item.id)}
            key={item.id}
        >
            <MuiAlert elevation={6} variant="filled" severity={item.type}>
                {item.message}
            </MuiAlert>
        </Snackbar>
    ));
}
