import styled from "styled-components";
import { Dialog } from "@material-ui/core";

export const StyledDialog = styled(Dialog)`
    & .MuiPaper-root,
    .MuiDialogContent-root {
        overflow-y: visible;
        max-height: 80vh;
        overflow-y: auto;
    }
    & .MuiPaper-root {
        max-width: 768px;
        width: 768px;
    }
`;
