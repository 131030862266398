// Core
import React from "react";
import { useStores } from "store";
import { useObserver } from "mobx-react";

// Components
import UserAvatar from "components/UserAvatar";
import PhotoUploader from "../PhotoUploader";

// Styles
import { Wrapper, StyledProgress } from "./styles";

function useStore() {
    const { authStore } = useStores();

    return useObserver(() => ({
        isUpdatingAvatar: authStore.isUpdatingAvatar,
        onUploadAvatar: authStore.onUploadAvatar,
    }));
}

export default function ProfileMenu({ me }) {
    const { isUpdatingAvatar, onUploadAvatar } = useStore();

    return (
        <Wrapper>
            <PhotoUploader
                onUpload={onUploadAvatar}
                disabled={isUpdatingAvatar}
            >
                <UserAvatar
                    firstName={me.firstname}
                    lastName={me.lastname}
                    avatar={me.avatar}
                />
            </PhotoUploader>
            {isUpdatingAvatar && <StyledProgress size={41} />}
        </Wrapper>
    );
}
