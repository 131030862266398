// Core
import React from "react";

// UI
import ButtonGroup from "@material-ui/core/ButtonGroup";

// Styles
import { StyledButton } from "./style";

// Constants
import { STATUS_COLORS } from "constants/managerReports";

export default function LogStatusHandler({ id, onUpdateLogStatus, status }) {
    const getColor = status => STATUS_COLORS[status];

    return (
        <>
            <ButtonGroup size="small" variant="contained">
                {status !== "approved" && (
                    <StyledButton
                        disableElevation
                        type={getColor("approved")}
                        onClick={() =>
                            onUpdateLogStatus({
                                id,
                                status: "approved",
                            })
                        }
                    >
                        approve
                    </StyledButton>
                )}
                {status !== "declined" && (
                    <StyledButton
                        disableElevation
                        type={getColor("declined")}
                        onClick={() =>
                            onUpdateLogStatus({
                                id,
                                status: "declined",
                            })
                        }
                    >
                        decline
                    </StyledButton>
                )}
            </ButtonGroup>
        </>
    );
}
