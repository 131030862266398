// Core
import { observable, action, decorate, runInAction } from "mobx";

// Services
import activityService from "../services/api/ActivityService";

export function distributeBy(key, array) {
    const object = array.reduce((acc, item) => {
        (acc[item[key]] || (acc[item[key]] = [])).push(item);
        return acc;
    }, {});

    return Object.entries(object).map(item => ({
        title: item[0],
        data: item[1],
    }));
}
class ActivityStore {
    constructor(rootStore) {
        this.activities = [];
        this.nextDate = false;
        this.rootStore = rootStore;
        this.me = undefined;
        this.token = undefined;
    }

    setInitialState = () => {
        this.activities = [];
        this.nextDate = false;
    };

    showLoader(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    showError = message => {
        this.rootStore.notificationStore.showNotification({
            type: "error",
            message,
        });
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    handleResponseActivities = ({ activities, next_date, current_date }) => {
        runInAction("set activity response", () => {
            this.activities = [
                ...this.activities,
                {
                    date: current_date,
                    activities: distributeBy("tree_id", activities),
                },
            ];
            this.nextDate = next_date;
        });
    };

    onGetActivities = async params => {
        try {
            this.showLoader(true);
            const data = await activityService.getActivities(params);
            const activities = data?.data?.data;
            const next_date = data?.data?.next_date;
            const current_date = data?.data?.current_date;
            if (activities.length) {
                this.handleResponseActivities({
                    activities,
                    next_date,
                    current_date,
                });
            }
            if (activities.length < 10 && next_date) {
                this.onGetActivities({ date: next_date });
            }
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.showLoader(false);
        }
    };
}

decorate(ActivityStore, {
    // state
    activities: observable,
    nextDate: observable,

    // actions
    onGetActivities: action,
    setInitialState: action,
});

export default ActivityStore;
