// Core
import { observable, action, decorate } from "mobx";
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "constants/pagination";

class PaginationStore {
    page = DEFAULT_PAGE;
    perPage = DEFAULT_PER_PAGE;
    meta = undefined;

    setInitialValues = initialValues => {
        this.page = initialValues?.page || DEFAULT_PAGE;
        this.perPage = initialValues?.perPage || this.perPage;
        this.meta = undefined;
    };

    setPage = number => {
        this.page = number;
    };

    setPerPage = number => {
        this.page = DEFAULT_PAGE;
        this.perPage = number;
    };

    setMeta = data => {
        this.meta = data;
    };
}

decorate(PaginationStore, {
    // state
    page: observable,
    perPage: observable,
    meta: observable,

    // actions
    setInitialValues: action,
    setPage: action,
    setPerPage: action,
    setMeta: action,
});

export default PaginationStore;
