export const TABLE_HEADERS = [
    "№",
    "Date",
    "Project Name",
    "Type",
    "From time",
    "To time",
    "Work Time",
    "Status",
];

export const validationErrorMessages = {
    timeInterval: "Time interval is invalid",
    project: "Project field is required",
    log: "Log type is required",
    descriptions: "Description field is required",
    work_type: "Scope type is required",
};

export const WORK_TYPES = [
    { label: "In Scope", value: "in scope" },
    { label: "Change request", value: "change", color: "blue" },
    { label: "Bug", value: "bug", color: "red" },
];

export const WORK_TYPE = [
    { label: "In Scope", value: "in scope" },
    { label: "Change request", value: "change", color: "blue" },
    { label: "Bug", value: "bug", color: "red" },
];
