// Core
import { observable, action, decorate } from "mobx";

// Constants
import { IMAGES_URL } from "services/api/constants";

import { getUserAvatar } from "../utils/developersUtils";

// Services
import authService from "../services/api/AuthService";
import userService from "../services/api/UserService";

class AuthStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.me = undefined;
        this.token = undefined;
        this.isUpdatingAvatar = false;
    }

    showLoader(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    showError = message => {
        this.rootStore.notificationStore.showNotification({
            type: "error",
            message,
        });
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    setInitialState = () => {
        this.token = undefined;
        this.me = undefined;

        authService.clearInterceptor(this.authInterceptor);
    };

    onLogin = async data => {
        try {
            this.showLoader(true);
            const response = await authService.login(data);
            const token = response?.data?.access_token;

            if (token) {
                await localStorage.setItem("token", token);
                this.setUserToken(token);
            }
        } catch (err) {
            console.error(err);
            this.showNotification("error", err?.response?.data?.message);
            this.setInitialState();
        } finally {
            this.showLoader(false);
        }
    };

    onLogout = async data => {
        try {
            await authService.logout();
            await localStorage.removeItem("token");

            authService.clearInterceptor(this.authInterceptor);
            this.setInitialState();
        } catch (error) {
            console.error(error);
        }
    };

    onGetMe = async () => {
        try {
            const response = await authService.getMe();
            return response.data;
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        }
    };

    dispatchLoginFulfilled = (me, token) => {
        this.me = { ...me, avatar: getUserAvatar(me?.avatar) };
        this.token = token;
    };

    setUserToken = token => {
        this.token = token;
    };

    onForgotPassword = async body => {
        try {
            this.showLoader(true);
            await authService.forgotPassword(body);
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.showLoader(false);
        }
    };

    onResetPassword = async body => {
        try {
            this.showLoader(true);
            await authService.resetPassword(body);
            this.showNotification(
                "success",
                "Password was successfully changed"
            );
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.showLoader(false);
        }
    };

    addAuthHeaderInterceptor = async () => {
        const token = await localStorage.getItem("token");

        authService.clearInterceptor(this.authInterceptor);

        this.authInterceptor = await authService.addAuthHeaderInterceptor({
            token,
            onError: this.setInitialState,
        });
    };
    onUploadAvatar = async avatar => {
        this.isUpdatingAvatar = true;

        try {
            const formData = new FormData();
            formData.append("avatar", avatar);

            const data = await userService.uploadAvatar(formData);

            this.me = {
                ...this.me,
                avatar: `${IMAGES_URL}/uploads${data?.data?.data?.avatar}`,
            };
            this.showNotification("success", "Avatar updated");
        } catch (e) {
            this.showNotification("error", "Something went wrong");
        } finally {
            this.isUpdatingAvatar = false;
        }
    };
}

decorate(AuthStore, {
    // state
    me: observable.ref,
    token: observable,
    isUpdatingAvatar: observable,

    //actions
    setInitialState: action,
    onLogin: action,
    dispatchLoginFulfilled: action,
    setUserToken: action,
    addAuthHeaderInterceptor: action,
    onGetMe: action,
    onForgotPassword: action,
    onUploadAvatar: action,
});

export default AuthStore;
