// Core
import React, { useEffect } from "react";
import { useObserver } from "mobx-react";

// UI
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalFonts from "assets/fonts";
import Notification from "components/Notification";

// Navigation
import Navigation from "./navigation";

// Store
import { useStores } from "./store";

// Components
import PageLoader from "./components/PageLoader";

// Hooks
function useAuthData() {
    const {
        authStore,
        loadersStore: { setLoadingPage },
    } = useStores();

    return useObserver(() => ({
        addAuthHeaderInterceptor: authStore.addAuthHeaderInterceptor,
        dispatchLoginFulfilled: authStore.dispatchLoginFulfilled,
        setInitialState: authStore.setInitialState,
        onGetMe: authStore.onGetMe,
        onLogout: authStore.onLogout,
        setLoadingPage: setLoadingPage,
        me: authStore.me,
        token: authStore.token,
    }));
}

function App() {
    const {
        dispatchLoginFulfilled,
        addAuthHeaderInterceptor,
        setInitialState,
        onGetMe,
        setLoadingPage,
        me,
        token: myToken,
        onLogout,
    } = useAuthData();
    const [userLoaded, setLoaded] = React.useState(false);

    useEffect(() => {
        async function getTokenFromLocalStorage() {
            try {
                const token = localStorage.getItem("token") || myToken;

                if (token) {
                    setLoadingPage(true);
                    await addAuthHeaderInterceptor(token);
                    const responseMe = await onGetMe();
                    const me = responseMe?.data;
                    dispatchLoginFulfilled(me, token);
                } else {
                    setInitialState();
                }
            } catch (e) {
                setInitialState();
            } finally {
                setLoadingPage(false);
                setLoaded(true);
            }
        }
        if (!me) {
            getTokenFromLocalStorage();
        }
    }, [
        addAuthHeaderInterceptor,
        dispatchLoginFulfilled,
        me,
        myToken,
        onGetMe,
        setInitialState,
        setLoadingPage,
    ]);

    return (
        userLoaded && (
            <>
                <PageLoader />
                <Navigation me={me} onLogout={onLogout} />
                <CssBaseline />
                <GlobalFonts />
                <Notification />
            </>
        )
    );
}

export default App;
