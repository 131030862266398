import React from "react";
import styled from "styled-components";
import { TableCell, TableContainer } from "@material-ui/core";

export const StyledTableCell = styled(({ status, ...otherProps }) => (
    <TableCell {...otherProps} />
))`
    color: ${({ type, theme }) => theme.palette[type]?.main};
`;

export const StyledTableContainer = styled(TableContainer)`
    tr > th:first-child,
    tr > td:first-child {
        padding: 16px 3px;
        text-align: center;
    }
    tr > th:last-child,
    tr > td:last-child {
        width: 70px;
    }
    tr > td:last-child {
        text-align: center;
    }
`;
