import React from "react";
import { ContainedButton, OutlinedButton } from "./styles";

function DialogActionButton(props) {
    return props.variant === "contained" ? (
        <ContainedButton {...props} />
    ) : (
        <OutlinedButton {...props} />
    );
}

export default DialogActionButton;
