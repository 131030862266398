// Core
import { action, decorate, observable, runInAction } from "mobx";
import exportService from "../services/api/ExportService";

//constants
import { MAX_PER_PAGE } from "constants/pagination";
import { EXCEL_TYPE } from "constants/export";
import userService from "services/api/UserService";
import projectService from "services/api/ProjectService";

class ExportStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.historyItems = [];
        this.userList = [];
        this.projectList = [];
        this.totalLogTime = undefined;
    }

    setPaginationMeta = data => {
        this.rootStore.paginationStore.setMeta(data);
    };

    showErrorMessage = message => {
        this.rootStore.notificationStore.showNotification({
            message,
            type: "error",
        });
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    setIsLoading(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    downloadFile(response, filename) {
        const blob = new Blob([response.data], {
            type: EXCEL_TYPE,
        });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
    }

    onDownload = async params => {
        try {
            this.setIsLoading(true);
            const response = await exportService.download(params);
            if (response.headers["content-type"] === EXCEL_TYPE) {
                this.downloadFile(response, response.headers["x-file"]);
            } else {
                this.showErrorMessage("Error exporting report");
            }
        } catch (error) {
            this.showErrorMessage(error?.message);
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    onGetHistoryItems = async params => {
        try {
            this.setIsLoading(true);
            const response = await exportService.getHistoryItems({
                page: this.page,
                per_page: this.perPage,
                sort: { created_at: "desc" },
                ...params,
            });
            runInAction("setHistoryItems", () => {
                this.historyItems = response?.data?.data;
            });
            this.setPaginationMeta(response?.data?.meta);
        } catch (error) {
            this.showErrorMessage(error?.response?.data?.message);
            throw error;
        } finally {
            this.setIsLoading(false);
        }
    };

    onGetProjectList = async () => {
        try {
            this.setIsLoading(true);
            const projectList = await projectService.getProjectList({
                per_page: MAX_PER_PAGE,
            });
            runInAction("setProjectList", () => {
                this.projectList = projectList?.data?.data;
            });
        } catch (error) {
            this.showErrorMessage(error?.response?.data?.message);
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    onGetUserList = async () => {
        try {
            this.setIsLoading(true);
            const userList = await userService.getUserList({
                per_page: MAX_PER_PAGE,
            });
            runInAction("setUserList", () => {
                this.userList = userList?.data?.data;
            });
        } catch (error) {
            this.showErrorMessage(error?.response?.data?.message);
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    onGetTotalLogTime = async params => {
        try {
            this.setIsLoading(true);
            const response = await exportService.getTotalLogTime(params);
            runInAction("set total log time", () => {
                this.totalLogTime = response?.data?.data?.total;
            });
        } catch (error) {
            this.showErrorMessage(error?.response?.data?.message);
        } finally {
            this.setIsLoading(false);
        }
    };

    onDeleteHistoryItem = async id => {
        try {
            this.setIsLoading(true);
            await exportService.deleteHistoryItem(id);
            this.showNotification("success", "History item deleted");
        } catch (error) {
            this.showErrorMessage(error?.response?.data?.message);
        } finally {
            this.setIsLoading(false);
        }
    };
}

decorate(ExportStore, {
    //observables
    historyItems: observable,
    page: observable,
    perPage: observable,
    userList: observable,
    projectList: observable,
    totalLogTime: observable,

    // actions
    onDownload: action,
    onGetHistoryItems: action,
    onGetProjectList: action,
    onGetUserList: action,
    onGetTotalLogTime: action,
    onDeleteHistoryItem: action,
});

export default ExportStore;
