export const FULL_DATE_FORMAT = "DD.MM.YYYY";
export const FULL_DATE_SHORT = "DD.MM.YY";
export const FULL_DATE_SERVER = "DD-MM-YYYY";

export const DATE_FORMAT = "DD.MM";
export const DAY_FORMAT = "DD";
export const TIME_FORMAT = "HH:mm";
export const DATE_AND_MONTH = "DD MMMM";

export const HOUR_IN_MINUTES = 60;
export const MINUTES_IN_HOURS = 60;

export const MONDAY = 1;
export const SUNDAY = 0;
