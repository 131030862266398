// Core
import React, { useState } from "react";

// UI
import { Container, Tabs, Tab } from "@material-ui/core";

// Components
import ReportHistory from "./ReportsHistory";
import Reports from "./Reports";

const TABS = [
    { id: "report", title: "Report", Component: Reports },
    { id: "report-history", title: "Report history", Component: ReportHistory },
];

export default function ReportsPage() {
    const [selectedTab, setValue] = useState(0);

    const handleChange = (event, index) => {
        setValue(index);
    };

    const CurrentPage = TABS[selectedTab].Component;

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                {TABS.map(({ id, title }) => (
                    <Tab key={id} label={title} />
                ))}
            </Tabs>
            <Container>
                <CurrentPage />
            </Container>
        </>
    );
}
