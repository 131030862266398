// Core
import React from "react";
import DayjsUtils from "@date-io/dayjs";
import "dayjs/locale/en-sg";

// UI
import { Box } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// constants
import { FULL_DATE_FORMAT } from "constants/dateFormats";

function DateRangePicker({ fromDate, toDate, setFilter, setErrorDates }) {
    const changeDateFrom = date => {
        setFilter("fromDate", date);
    };

    const changeDateTo = date => {
        setFilter("toDate", date);
    };

    const onErrorFrom = error => {
        if (!error) {
            setErrorDates("dateFrom", false);
            return;
        }
        setErrorDates("dateFrom", true);
    };
    const onErrorTo = error => {
        if (!error) {
            setErrorDates("dateTo", false);
            return;
        }
        setErrorDates("dateTo", true);
    };

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils} locale="en-sg">
            <Box display="flex">
                <Box mr={1}>
                    {toDate ? (
                        <KeyboardDatePicker
                            autoOk
                            value={fromDate}
                            label="From date"
                            maxDate={toDate}
                            onChange={changeDateFrom}
                            format={FULL_DATE_FORMAT}
                            onError={onErrorFrom}
                            cancelLabel={null}
                            okLabel={null}
                        />
                    ) : (
                        <KeyboardDatePicker
                            autoOk
                            value={fromDate}
                            label="From date"
                            onChange={changeDateFrom}
                            format={FULL_DATE_FORMAT}
                            onError={onErrorFrom}
                            cancelLabel={null}
                            okLabel={null}
                        />
                    )}
                </Box>
                <Box mr={1}>
                    {fromDate ? (
                        <KeyboardDatePicker
                            autoOk
                            label="To date"
                            value={toDate}
                            onChange={changeDateTo}
                            minDate={fromDate}
                            format={FULL_DATE_FORMAT}
                            onError={onErrorTo}
                            cancelLabel={null}
                            okLabel={null}
                        />
                    ) : (
                        <KeyboardDatePicker
                            autoOk
                            label="To date"
                            value={toDate}
                            onChange={changeDateTo}
                            format={FULL_DATE_FORMAT}
                            onError={onErrorTo}
                            cancelLabel={null}
                            okLabel={null}
                        />
                    )}
                </Box>
            </Box>
        </MuiPickersUtilsProvider>
    );
}

export default DateRangePicker;
