import React from "react";

import { StyledAvatar } from "./style";

class UserAvatar extends React.Component {
    render() {
        const {
            firstName,
            lastName,
            disablePointer,
            avatar,
            size = 40,
        } = this.props;

        if (!firstName || !lastName) return null;

        return (
            <StyledAvatar
                disablePointer={disablePointer}
                src={avatar}
                size={size}
            >
                {`${firstName[0] || ""}${lastName[0] || ""}`}
            </StyledAvatar>
        );
    }
}

export default UserAvatar;
