// Core
import React from "react";
import dayjs, { formatTimeDuration } from "utils/dateUtils";

// UI
import { TableHead, TableBody, Table, Box } from "@material-ui/core";
import TableRow from "components/TableRow";
import TableCell from "components/TableCell";
import ColoredName from "components/ColoredName";
import Chip from "components/Chip";

// Constants
import { TABLE_HEADERS } from "constants/managerReports";

// Utils
import { getType } from "utils/managerReportsUtils";

export default function LogReportTable({ logList, onEdit }) {
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {TABLE_HEADERS.map(item => (
                        <TableCell noWrap key={item}>
                            {item}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {logList.length ? (
                    logList.map(log => (
                        <TableRow
                            hover
                            key={`log-${log.id}`}
                            onClick={() =>
                                onEdit({
                                    ...log,
                                    project_id: log?.project_id?.id,
                                })
                            }
                        >
                            <TableCell noWrap>
                                {dayjs(log.date, "DD-MM-YYYY").format(
                                    "DD.MM.YYYY"
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {dayjs(log.date, "DD-MM-YYYY").customWeek()}
                            </TableCell>
                            <TableCell>{`${log.user_id.firstname} ${log.user_id.lastname}`}</TableCell>
                            <TableCell>
                                <ColoredName
                                    text={log.project_id.name}
                                    fontSize={14}
                                    color={log.project_id.color}
                                />
                            </TableCell>
                            <TableCell>{log.descriptions}</TableCell>
                            <TableCell>{log.work_type}</TableCell>
                            <TableCell noWrap>
                                {formatTimeDuration(log.total)}
                            </TableCell>
                            <TableCell>
                                {log.last_generated
                                    ? dayjs(
                                          log.last_generated,
                                          "YYYY-MM-DD HH:mm:ss"
                                      ).calendar()
                                    : "-"}
                            </TableCell>
                            <TableCell>
                                <Chip
                                    type={getType(log.status)}
                                    label={log.status}
                                />
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <Box p={3} component="td">
                            {"Empty"}
                        </Box>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
