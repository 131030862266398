import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// ui
import Time from "../Time";

//utils
import {
    formatFromSecondsToDecimal,
    formatFromSecondsToObject,
} from "utils/developersUtils";

const EmptyValue = () => <Typography variant="subtitle1">0</Typography>;
const Caption = () => (
    <Box mr={1}>
        <Typography variant="overline" color="textSecondary">
            <b>Total time: </b>
        </Typography>
    </Box>
);

function TotalTime({ totalLogTime }) {
    if (!totalLogTime) {
        return (
            <>
                <Caption />
                <EmptyValue />
            </>
        );
    }

    const totalTimeObject = formatFromSecondsToObject(totalLogTime);

    const totalDecimalTime = formatFromSecondsToDecimal(totalLogTime);

    return (
        <>
            <Caption />
            {Object.entries(totalTimeObject).map(([key, value], i) => {
                return <Time value={value} unit={key} key={`${i}_${value}`} />;
            })}
            <Box component={Typography} variant="subtitle1" px={1}>
                {"/"}
            </Box>
            <Time value={`${totalDecimalTime}`} unit="h" />
        </>
    );
}

export default TotalTime;
