// Core
import React, { useState } from "react";
import { useObserver } from "mobx-react";

// UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// Store
import { useStores } from "../../store";

// Styles
import { StyledContainer, StyledLink } from "./styles";

function useAuthData() {
    const {
        authStore: { onLogin },
    } = useStores();
    return useObserver(() => ({
        onLogin,
    }));
}

export default function SignIn() {
    const [data, setForm] = useState({ email: "", password: "" });
    const { onLogin } = useAuthData();

    return (
        <StyledContainer>
            <form>
                <Box display="flex" flexDirection="column" mb={1}>
                    <Box mb={1} width="100%">
                        <TextField
                            id="email-input"
                            label="Email"
                            fullWidth
                            onChange={e =>
                                setForm({ ...data, email: e.target.value })
                            }
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            id="password-input"
                            label="Password"
                            type="password"
                            fullWidth
                            onChange={e =>
                                setForm({ ...data, password: e.target.value })
                            }
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                    mt={1}
                >
                    <Box>
                        <StyledLink to={"/forgot-password"}>
                            Forgot password
                        </StyledLink>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onLogin(data);
                        }}
                    >
                        Sign in
                    </Button>
                </Box>
            </form>
        </StyledContainer>
    );
}
