// Core
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useStores } from "store";
import { useObserver } from "mobx-react";
// UI
import { Container, Box, Tooltip } from "@material-ui/core";
import InReviewItem from "./InReviewItem";
import ReportPagination from "components/ReportPagination";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Settings from "./Settings";
import HistoryByLogModal from "./HistoryByLogModal";

// Styles
import { StyledToolbar } from "./styles";
import WorkItemAddEditForm from "../../components/WorkItemAddEditForm";
import { formatToServerString } from "../../utils/dateUtils";

import FilterSelectorPanel from "../manager-reports/Reports/FilterSelectorPanel";
import { INITIAL_VALUE } from "../manager-reports/Reports/WeekSelector";

import { WORK_ITEM_STATUSES } from "../manager-reports/Reports/StatusSelector";

const DISABLED_STATUS_VALUES = ["declined"];

function useStore() {
    const { logStore, paginationStore } = useStores();

    return useObserver(() => ({
        logs: logStore.logs,
        logTypes: logStore.logTypes,
        onGetLogs: logStore.onGetLogs,
        onUpdateLog: logStore.onUpdateLog,
        setEmptyLogs: logStore.setEmptyLogs,
        onUpdateLogStatus: logStore.onUpdateLogStatus,
        onGetLogTypes: logStore.onGetLogTypes,
        onGetProjectList: logStore.onGetProjectList,
        page: paginationStore.page,
        perPage: paginationStore.perPage,
        projectList: logStore.projectList,
        onGetDeclinedLogs: logStore.onGetDeclinedLogs,
        userList: logStore.userList,
        onGetUserList: logStore.onGetUserList,
        getHistoryByLog: logStore.getHistoryByLog,
        clearHistoryByLog: logStore.clearHistoryByLog,
        setInitialPagination: paginationStore.setInitialValues,
    }));
}

export default function LogItems() {
    const {
        logs,
        onGetLogs,
        page,
        perPage,
        onGetLogTypes,
        onGetProjectList,
        logTypes,
        onUpdateLog,
        onUpdateLogStatus,
        setEmptyLogs,
        projectList,
        userList,
        onGetUserList,
        setInitialPagination,
    } = useStore();

    const [editingLog, setLogToEdit] = useState(null);
    const [isOpenModal, setModalVisible] = useState(false);

    const [selectedWeeks, setSelectedWeeks] = useState([INITIAL_VALUE]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [historyLogId, setHistoryLogId] = useState(null);
    const [selectedWorkItemStatus, setSelectedWorkItemStatus] = useState([
        WORK_ITEM_STATUSES[0],
    ]);
    const [selectedScopeType, setSelectedScopeType] = useState();

    const [errorFromDate, setErrorFromDate] = useState(false);
    const [errorToDate, setErrorToDate] = useState(false);

    const filter = useMemo(
        () => ({
            users: selectedUsers.map(item => item.id),
            projects: selectedProjects.map(item => item.id),
            weeks: selectedWeeks.map(item => `${item.week},${item.year}`),
            page,
            per_page: perPage,
            status: selectedWorkItemStatus,
            work_type: selectedScopeType?.value,
            from_date: fromDate?.isValid()
                ? formatToServerString(fromDate)
                : null,
            to_date: toDate?.isValid() ? formatToServerString(toDate) : null,
        }),
        [
            selectedUsers,
            selectedProjects,
            selectedWeeks,
            page,
            perPage,
            selectedWorkItemStatus,
            selectedScopeType,
            fromDate,
            toDate,
        ]
    );

    useEffect(() => {
        const noDateRange = fromDate === null && toDate === null;
        const isValidDateRange = fromDate?.isValid() && toDate?.isValid();
        if (isValidDateRange || noDateRange) {
            onGetLogs(filter);
        }
    }, [filter, onGetLogs, errorFromDate, errorToDate, fromDate, toDate]);

    useEffect(() => {
        return setEmptyLogs;
    }, [setEmptyLogs]);

    const updateLogStatus = async data => {
        await onUpdateLogStatus(data);
        onGetLogs(filter);
    };

    const onEditWorkItem = async (...param) => {
        await onUpdateLog(...param);
        onGetLogs(filter);
        setLogToEdit(null);
    };

    const onErrorDates = (type, value) => {
        const setValue = {
            dateFrom: () => setErrorFromDate(value),
            dateTo: () => setErrorToDate(value),
        };

        setValue[type]();
    };

    useEffect(() => {
        if (selectedWeeks.length) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedWeeks]);

    const onFilterChange = useCallback(
        (type, value) => {
            setInitialPagination();
            const setValueTypes = {
                fromDate: () => {
                    setFromDate(value);
                    setSelectedWeeks([]);
                },
                toDate: () => {
                    setToDate(value);
                    setSelectedWeeks([]);
                },
                selectedWeeks: () => setSelectedWeeks(value),
                selectedProjects: () => setSelectedProjects(value),
                selectedUsers: () => setSelectedUsers(value),
                selectedWorkItemStatus: () => setSelectedWorkItemStatus(value),
                scopeType: () => setSelectedScopeType(value),
            };

            setValueTypes[type]();
        },
        [setInitialPagination]
    );

    useEffect(() => {
        onGetProjectList();
        onGetUserList();
        onGetLogTypes();

        return setEmptyLogs;
    }, [onGetProjectList, onGetUserList, setEmptyLogs, onGetLogTypes]);

    return (
        <Container position="relative">
            <StyledToolbar>
                <Tooltip title="Add work item type" placement="top">
                    <IconButton onClick={() => setModalVisible(true)}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
            </StyledToolbar>
            <FilterSelectorPanel
                fromDate={fromDate}
                toDate={toDate}
                selectedWeeks={selectedWeeks}
                selectedProjects={selectedProjects}
                selectedUsers={selectedUsers}
                selectedWorkItemStatus={selectedWorkItemStatus}
                setFilter={onFilterChange}
                projectList={projectList}
                logs={logs}
                setErrorDates={onErrorDates}
                userList={userList}
                selectedScopeType={selectedScopeType}
            />
            <Container>
                <Box my={2} margin="auto" width="90%" maxWidth="900px">
                    {logs.map(item => (
                        <InReviewItem
                            {...item}
                            key={`log-item-${item.id}-${item.descriptions}`}
                            onUpdateLog={onUpdateLog}
                            onUpdateLogStatus={updateLogStatus}
                            logTypes={logTypes}
                            disabled={DISABLED_STATUS_VALUES.includes(
                                item.status
                            )}
                            onEdit={() =>
                                setLogToEdit({
                                    ...item,
                                    project_id: item?.project_id?.id,
                                })
                            }
                            onShowHistory={() => setHistoryLogId(item.id)}
                        />
                    ))}
                    {logs?.length > 0 && <ReportPagination />}
                    {!logs?.length && (
                        <Box my={2} textAlign="center">
                            You don`t have any log items for review
                        </Box>
                    )}
                </Box>
            </Container>
            <Settings
                isOpen={isOpenModal}
                onClose={() => setModalVisible(false)}
            />
            <WorkItemAddEditForm
                open={!!editingLog}
                handleClose={() => setLogToEdit(null)}
                projects={projectList}
                logTypes={logTypes}
                submitForm={onEditWorkItem}
                deleteLog={null}
                initialData={editingLog}
                selectedDay={null}
                selectedWeek={null}
            />
            <HistoryByLogModal
                id={historyLogId}
                onClose={() => setHistoryLogId(null)}
            />
        </Container>
    );
}
