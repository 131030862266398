import { createMuiTheme } from "@material-ui/core";
import { green, red, blue, grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#a8bcd8",
        },
        logo: "#d1d2d4",
        success: {
            main: green[500],
        },
        error: {
            main: red[200],
        },
        info: {
            main: blue[500],
        },
        default: {
            main: grey[500],
        },
    },
});

export default theme;
