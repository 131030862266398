// Core
import React, { useEffect } from "react";
import { useObserver } from "mobx-react";

// Store
import { useStores } from "store";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivitiesList from "../../Activity/ActivitiesList";

import { StyledFormDialog } from "./styles.js";

function useStore() {
    const { logStore } = useStores();

    return useObserver(() => ({
        isLoadingHistoryByLog: logStore.isLoadingHistoryByLog,
        historyByLog: logStore.historyByLog,
        nextDate: logStore.nextDate,
        historyNextDate: logStore.historyNextDate,

        getHistoryByLog: logStore.getHistoryByLog,
        clearHistoryByLog: logStore.clearHistoryByLog,
    }));
}

export default ({ id, onClose }) => {
    const {
        historyByLog,
        historyNextDate,
        getHistoryByLog,
        clearHistoryByLog,
    } = useStore();

    useEffect(() => {
        if (!id) return;

        getHistoryByLog(id);

        return () => {
            clearHistoryByLog();
        };
    }, [id, clearHistoryByLog, getHistoryByLog]);

    return (
        <StyledFormDialog
            id="modal-log-history"
            open={!!id}
            handleClose={onClose}
            title="Work Log History"
        >
            <div
                id="scrollableDiv"
                style={{ height: "54vh", overflow: "scroll" }}
            >
                <InfiniteScroll
                    dataLength={historyByLog?.length} //This is important field to render the next data
                    next={() => {
                        getHistoryByLog(id, { date: historyNextDate });
                    }}
                    hasMore={!!historyNextDate}
                    loader={<p>Loading...</p>}
                    scrollableTarget="scrollableDiv"
                    style={{ height: "64vh" }}
                >
                    {historyByLog.map((day, i) => (
                        <ActivitiesList
                            key={`date-${day.date}-${i}`}
                            activities={day.activities}
                            date={day.date}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        </StyledFormDialog>
    );
};
