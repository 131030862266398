// Core
import { observable, action, decorate } from "mobx";

class NotificationStore {
    notificationQueue = [];

    showNotification = data => {
        this.notificationQueue = [
            ...this.notificationQueue,
            {
                ...data,
                duration: 2000,
                id: this.notificationQueue.length,
            },
        ];
    };
    hideNotification = id => {
        this.notificationQueue = this.notificationQueue.filter(
            item => item.id !== id
        );
    };
}

decorate(NotificationStore, {
    // state
    notificationQueue: observable,

    // actions
    showNotification: action,
    hideNotification: action,
});

export default NotificationStore;
