// Core
import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";

export const ContainedButton = styled(Button)`
    background-color: ${({ type, theme }) => theme.palette[type]?.main};
    min-width: 90px;
    min-height: 30px;
    color: white;
    &:hover {
        background-color: ${({ type, theme }) => theme.palette[type]?.light};
    }
`;

export const OutlinedButton = styled(({ type, ...otherProps }) => (
    <Button {...otherProps} />
))`
    color: ${({ type, theme }) => theme.palette[type]?.main};
`;
