// Core
import React, { useEffect } from "react";
import { useObserver } from "mobx-react";

// UI
import { TablePagination, Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// constants
import { ROWS_PER_PAGE } from "constants/pagination";

// Store
import { useStores } from "store";

function useStore() {
    const { paginationStore } = useStores();

    return useObserver(() => ({
        page: paginationStore.page,
        perPage: paginationStore.perPage,
        setPage: paginationStore.setPage,
        setPerPage: paginationStore.setPerPage,
        meta: paginationStore.meta,
        setInitialValues: paginationStore.setInitialValues,
    }));
}

export default function ReportPagination() {
    const {
        meta,
        page,
        perPage,
        setPage,
        setPerPage,
        setInitialValues,
    } = useStore();

    useEffect(() => setInitialValues, [setInitialValues]);

    if (!meta) {
        return null;
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <TablePagination
                rowsPerPageOptions={ROWS_PER_PAGE}
                component={"div"}
                count={meta.total}
                rowsPerPage={perPage}
                page={page - 1}
                onChangePage={(e, newPage) => setPage(newPage + 1)}
                onChangeRowsPerPage={e => setPerPage(e.target.value)}
            />
            <Pagination
                count={meta.last_page}
                color="primary"
                page={meta.current_page}
                onChange={(e, newPage) => setPage(newPage)}
            />
        </Box>
    );
}
