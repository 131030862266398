// Core
import React, { useCallback } from "react";
import { useFormik } from "formik";
import { string, object, mixed } from "yup";

// UI
import { TextField, Grid, Box } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import FormDialog from "components/FormDialog";
import UserMultiSelect from "../UserMultiSelect";
import TypeSelector from "../TypeSelector";
import DialogActionButton from "components/DialogActionButton";
import ColorPicker from "components/ColorPicker";

// Styles
import { StyledGridContainer } from "./styles";

// Utils
import { getInitialFormValues } from "utils/projectUtils";

// Constants

let schema = object().shape({
    alias: string().required(),
    name: string().required(),
    descriptions: string().required(),
    type: string().required(),
    color: mixed(object(), string()),
});

export default function LogForm({
    open,
    onClose,
    userList,
    projectTypes,
    handleSubmit,
    initialData,
    handleDeleteProject,
    onGetProjectUserList,
}) {
    const initialValues = getInitialFormValues(initialData);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: schema,
        onSubmit: (data, { resetForm }) => {
            handleSubmit(
                {
                    ...data,
                    users: data.users.map(user => user.id),
                },
                initialData?.id
            ).then(() => {
                resetForm(getInitialFormValues());
            });
        },
    });

    const onCancel = () => {
        formik.resetForm(getInitialFormValues());
        onClose();
    };

    const onColorChange = useCallback(
        val => formik.setFieldValue("color", val),
        [formik]
    );

    return (
        <FormDialog open={open} handleClose={onClose} title="Project:">
            <StyledGridContainer>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Box>
                            <TextField
                                required
                                fullWidth
                                error={!!formik.errors.name}
                                id="name"
                                label="Project name"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.name}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box>
                            <TextField
                                required
                                fullWidth
                                error={!!formik.errors.alias}
                                id="alias"
                                label="Alias"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.alias}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <TextField
                        required
                        error={!!formik.errors.descriptions}
                        id="descriptions"
                        defaultValue={formik.values.descriptions}
                        onChange={formik.handleChange}
                        label="Description"
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserMultiSelect
                        onChange={formik.setFieldValue}
                        onGetProjectUserList={onGetProjectUserList}
                        values={formik.values.users}
                        userList={userList}
                        id={"users"}
                    />
                </Grid>
                <Grid container>
                    <TypeSelector
                        value={formik.values.type}
                        error={formik.errors.type}
                        projectTypes={projectTypes}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid>
                    <ColorPicker
                        activeColor={formik.values.color}
                        onChange={onColorChange}
                    />
                </Grid>
                <DialogActions>
                    {initialData && (
                        <DialogActionButton
                            onClick={() => handleDeleteProject(initialData?.id)}
                            type="error"
                        >
                            Delete
                        </DialogActionButton>
                    )}
                    <DialogActionButton onClick={onCancel} type="default">
                        Cancel
                    </DialogActionButton>
                    <DialogActionButton
                        onClick={formik.handleSubmit}
                        variant="contained"
                        color="primary"
                        type="success"
                    >
                        Save
                    </DialogActionButton>
                </DialogActions>
            </StyledGridContainer>
        </FormDialog>
    );
}
