import React from "react";
import ProjectCard from "../ProjectCard";
import LogCard from "../LogCard";
import LogTypeCard from "../LogTypeCard";
import UserCard from "../UserCard";
import ExportHistoryCard from "../ExportHistoryCard";

function ActivityBlock({ item }) {
    const getCard = type => {
        switch (type) {
            case "Project":
                return <ProjectCard item={item} />;
            case "Log":
                return <LogCard item={item} />;
            case "LogType":
                return <LogTypeCard item={item} />;
            case "User":
                return <UserCard item={item} />;
            case "ExportHistory":
                return <ExportHistoryCard item={item} />;
            default:
                return type;
        }
    };

    return getCard(item.model);
}

export default ActivityBlock;
