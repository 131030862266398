import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Wrapper = styled.div`
    position: relative;
`;

export const StyledProgress = styled(CircularProgress)`
    position: absolute;
    top: 0;
    color: #2196f3;
`;
