import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const ListItems = styled.div(
    ({ isActive, color }) => `
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: ${color || "#FAF7F7"};
      border-width: 2px;
      border: 4px solid ${isActive ? "#747474" : "#fff"};
      box-sizing: border-box;
      cursor: pointer;
      transition: all linear 0.2s;
`
);

export const CustomPicker = styled.div`
    img {
        cursor: pointer;
    }
`;

export const CustomPickerInput = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    left: 50%;
    bottom: 0;
`;
