// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class ExportService {
    download = params =>
        axios.get(`${API_URL}/export`, {
            params,
            responseType: "blob",
        });
    getHistoryItems = params =>
        axios.get(`${API_URL}/export-history`, { params });

    getTotalLogTime = params =>
        axios.get(`${API_URL}/export/total-time`, { params });
    deleteHistoryItem = id => axios.delete(`${API_URL}/export-history/${id}`);
}

const exportService = new ExportService();

export default exportService;
