import React from "react";

import { ThemeProvider } from "styled-components";
import theme from "../../constants/theme";
import { StylesProvider } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core";

export default function ({ children }) {
    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
}
