import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const StyledButton = styled(
    ({ isActive, hasLogs, isRejected, ...otherProps }) => (
        <Button {...otherProps} />
    )
)`
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ isActive, hasLogs, theme, isRejected }) => {
        if (isActive) return theme.palette.primary.main;
        if (isRejected) return theme.palette.error.main;
        if (hasLogs) return theme.palette.success.main;
        return "transparent";
    }};
    color: ${({ isActive, hasLogs, theme }) => {
        if (isActive) return theme.palette.text.primary;
        if (hasLogs) return theme.palette.text.secondary;
        return theme.palette.text.secondary;
    }};
    border: ${({ isActive }) =>
        `2px solid ${isActive ? "#37394799" : "transparent"}`};
    box-sizing: border-box;

    &:hover {
        border: 2px solid #37394799;
    }
`;
