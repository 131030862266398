// Core
import React from "react";
import dayjs, { formatTimeDuration } from "utils/dateUtils";

// UI
import {
    TableHead,
    TableBody,
    Table,
    Box,
    Typography,
} from "@material-ui/core";
import TableRow from "components/TableRow";
import TableCell from "components/TableCell";
import Chip from "components/Chip";
import StyledPaper from "components/StyledPaper";
import ColoredName from "components/ColoredName";

// Constants
import { TABLE_HEADERS, WORK_TYPES } from "constants/developerReport";
import { FULL_DATE_SHORT, FULL_DATE_FORMAT } from "constants/dateFormats";

// Utils
import { getTotalTimeObject } from "utils/developerReportUtils";
import { getType } from "utils/managerReportsUtils";

// Styles
import { StyledTableCell, StyledTableContainer } from "./style";

export default function ({
    logList,
    handleEditLog,
    selectedDay,
    selectedWeek,
    logTypes,
    setTotalWeekTime,
    setTotalDayTime,
}) {
    const [filteredLogs, setFilteredLogs] = React.useState([]);

    React.useEffect(() => {
        let logs = logList;
        if (selectedDay) {
            logs = logList.filter(item =>
                dayjs(item.date, FULL_DATE_FORMAT).isSame(
                    dayjs(selectedDay),
                    "day"
                )
            );
        }
        if (!selectedWeek) {
            logs = [];
        }
        setFilteredLogs(logs);
    }, [logList, selectedDay, selectedWeek]);

    React.useEffect(() => {
        setTotalWeekTime(getTotalTimeObject(logList));
    }, [logList, setTotalWeekTime]);

    React.useEffect(() => {
        setTotalDayTime(getTotalTimeObject(filteredLogs));
    }, [filteredLogs, setTotalDayTime]);

    const editLog = log => {
        const validLog = { ...log, project_id: log.project_id?.id };

        handleEditLog(validLog);
    };

    const getLogTypeEnum = id => logTypes.find(item => item.id === id)?.type;
    const getWorkTypeLabel = value =>
        value ? WORK_TYPES.find(item => item.value === value)?.label : "";

    return (
        <StyledTableContainer component={StyledPaper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {TABLE_HEADERS.map(item => (
                            <TableCell key={item}>{item}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredLogs.length
                        ? filteredLogs.map((log, i) => (
                              <TableRow
                                  hover
                                  key={log.id}
                                  onClick={() => editLog(log)}
                              >
                                  <TableCell>{i + 1}</TableCell>
                                  <TableCell>
                                      {dayjs(log.date, FULL_DATE_FORMAT).format(
                                          FULL_DATE_SHORT
                                      )}
                                  </TableCell>
                                  <TableCell>
                                      <ColoredName
                                          text={log.project_id?.name}
                                          color={log.project_id?.color}
                                          fontSize={16}
                                      />{" "}
                                  </TableCell>
                                  <TableCell>
                                      {getLogTypeEnum(log.log_type_id)}{" "}
                                      {log.work_type && (
                                          <Typography variant="caption">{`(${getWorkTypeLabel(
                                              log.work_type
                                          )})`}</Typography>
                                      )}
                                  </TableCell>
                                  <TableCell>{log.from_time}</TableCell>
                                  <TableCell>{log.to_time}</TableCell>
                                  <TableCell>
                                      {formatTimeDuration(log.total)}
                                  </TableCell>
                                  <StyledTableCell>
                                      <Chip
                                          type={getType(log.status)}
                                          label={log.status}
                                      />
                                  </StyledTableCell>
                              </TableRow>
                          ))
                        : null}
                </TableBody>
            </Table>
            {!filteredLogs.length && (
                <Typography variant="subtitle1">
                    <Box p={2}>{"You don't have any logs"}</Box>
                </Typography>
            )}
        </StyledTableContainer>
    );
}
