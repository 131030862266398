// Core
import React from "react";

// UI
import { Button } from "@material-ui/core";

// Styles
import { StyledIcon } from "./styles";

function DownloadButton({ handleClick, children, disabled }) {
    return (
        <Button
            disabled={disabled}
            onClick={handleClick}
            startIcon={<StyledIcon disabled={disabled} />}
        >
            {children}
        </Button>
    );
}

export default DownloadButton;
