// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class ActivityService {
    getActivities = params =>
        axios.get(`${API_URL}/activities`, {
            params,
        });
}

const activityService = new ActivityService();

export default activityService;
