import styled from "styled-components";
import { TextField, Chip } from "@material-ui/core";

export const StyledTextField = styled(TextField)`
    & .MuiInputBase-root {
        min-height: 40px;
    }
`;

export const StyledChip = styled(Chip)`
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    margin-bottom: 2px;
`;
