// Core
import React from 'react'

//Styles
import {
    PageWrapper,
    PageContainer,
    MainText,
    SecondaryText,
    Content,
} from './styles'

const NoMatch = ({ history }) => (
    <PageWrapper>
        <PageContainer>
            <Content>
                <MainText>
                    Oops! The page you were looking for doesn’t exist
                </MainText>
                <SecondaryText>
                    You may have misstyped the address or the page may have
                    moved
                </SecondaryText>
                <div>
                    <button
                        onClick={() => {
                            history.push('/')
                        }}
                    >
                        Go to mainpage
                    </button>
                </div>
            </Content>
        </PageContainer>
    </PageWrapper>
)

export default NoMatch
