// core
import React from "react";

// UI
import DateRangePicker from "../DateRangePicker";
import ProjectSelector from "../ProjectSelector";
import UserSelector from "../UserSelector";
import WeekSelector from "../WeekSelector";
import StatusSelector from "../StatusSelector";
import { Box, Grid } from "@material-ui/core";
import ScopeTypeSelector from "../ScopeTypeSelector";

function FilterSelectorPanel({
    setFilter,
    projectList,
    userList,
    fromDate,
    toDate,
    selectedWeeks,
    selectedProjects,
    selectedUsers,
    selectedWorkItemStatus,
    setErrorDates,
    selectedScopeType,
}) {
    return (
        <Box display="flex" alignItems="flex-end" flexWrap="wrap" mt={5}>
            <Grid item xs={12} sm={6}>
                <Box mx={3}>
                    <DateRangePicker
                        setFilter={setFilter}
                        fromDate={fromDate}
                        toDate={toDate}
                        setErrorDates={setErrorDates}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box mx={3}>
                    <WeekSelector
                        setFilter={setFilter}
                        selectedWeeks={selectedWeeks}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                    <Box mx={3} mt={3}>
                        <ProjectSelector
                            projects={projectList}
                            setFilter={setFilter}
                            selectedProjects={selectedProjects}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box mx={3} mt={3}>
                        <UserSelector
                            selectedUsers={selectedUsers}
                            userList={userList}
                            setFilter={setFilter}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid xs={12} item sm={6}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Box mx={3} mt={3} flex={2}>
                        <StatusSelector
                            selectedWorkItemStatus={selectedWorkItemStatus}
                            setFilter={setFilter}
                        />
                    </Box>
                    <Box mx={3} mt={3} flex={1}>
                        <ScopeTypeSelector
                            value={selectedScopeType}
                            setFilter={setFilter}
                        />
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
}

export default FilterSelectorPanel;
