import React from "react";

import { StyledLabel } from "./styles";

const PhotoUploader = ({ children, onUpload, disabled }) => {
    return (
        <StyledLabel htmlFor="upload-photo" disabled={disabled}>
            <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                multiple={false}
                onChange={e => {
                    if (!e.target?.files) return;

                    onUpload(e.target?.files[0]);
                }}
            />
            {children}
        </StyledLabel>
    );
};

export default PhotoUploader;
