// Core
import React from "react";
//UI
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function ProjectMultiSelect({
    projectList,
    onChange,
    values,
    id,
}) {
    const getOptionLabel = option => option.name;

    const handleChange = (e, data) => {
        onChange(
            id,
            data.map(item => item.id)
        );
    };

    const getSelectedOptions = () => {
        const idSet = new Set(values);
        return projectList.filter(item => idSet.has(item.id));
    };

    return (
        <>
            <Autocomplete
                multiple
                id={id}
                options={projectList}
                getOptionLabel={getOptionLabel}
                value={getSelectedOptions()}
                filterSelectedOptions
                onChange={handleChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Select projects"
                    />
                )}
            />
        </>
    );
}
