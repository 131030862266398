import React from "react";

import { Wrapper, CustomBadge, Title } from "./styles";

const ColoredName = ({
    fontSize = 15,
    text = "",
    color = "transparent",
    className,
    icon,
}) => {
    return (
        <Wrapper className={className}>
            {icon ? icon : <CustomBadge size={fontSize / 2} color={color} />}
            <Title color={color} fontSize={fontSize}>
                {text}
            </Title>
        </Wrapper>
    );
};

export default ColoredName;
