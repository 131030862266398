// Core
import { observable, action, decorate } from "mobx";

class LoadersStore {
    isLoadingPage = true;

    setLoadingPage = status => {
        this.isLoadingPage = status;
    };
}

decorate(LoadersStore, {
    // state
    isLoadingPage: observable,

    // actions
    setLoadingPage: action,
});

export default LoadersStore;
