import dayjs from "dayjs";
import { FULL_DATE_FORMAT, FULL_DATE_SERVER } from "constants/dateFormats";

import customParseFormat from "dayjs/plugin/customParseFormat";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";

import relativeTime from "dayjs/plugin/relativeTime";
dayjs.Ls.en.weekStart = 1;
dayjs.extend(customParseFormat);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const SEC = 1000,
    MIN = SEC * 60,
    H = MIN * 60,
    D = H * 24,
    W = D * 7;

const customWeekPlugin = (o, c, d) => {
    c.prototype.customWeek = function (week = null, year) {
        if (week && year) {
            return d(this).year(year).startOf("year").week(week);
        }
        if (week && !year) {
            throw Error("Provide a year with week");
        }
        const endPoint = d(this).startOf("day");
        if (d(endPoint).endOf("isoWeek").year() !== d(endPoint).year()) {
            return 1;
        }
        return d(this).week(week);
    };
};
dayjs.extend(customWeekPlugin);

export const getStartOfWeek = ({ year, week }) =>
    dayjs().year(year).startOf("year").customWeek(week, year).day(1);

export const getEndOfWeek = ({ year, week }) => {
    const result = dayjs()
        .year(year)
        .startOf("year")
        .customWeek(week, year)
        .day(1)
        .add(6, "days");
    if (result.year() !== year) {
        return dayjs().year(year).endOf("year");
    }
    return result;
};

export const getWeekNumber = date => {
    const dateInstance = date ? dayjs(date, FULL_DATE_FORMAT) : dayjs();
    return {
        week: dateInstance.customWeek(),
        year: dateInstance.year(),
    };
};

const getWeeksForRange = (startDate, endDate) => {
    const results = [];
    let step = dayjs(startDate);

    while (!step.isAfter(endDate)) {
        let week = step.customWeek();
        let year = step.year();
        let nextStep = dayjs(step).add(1, "week");
        if (nextStep.isoWeekday() !== 1) {
            nextStep = nextStep.isoWeekday(1);
        }
        let endOfWeek = step.endOf("isoWeek");
        if (endOfWeek.year() !== year) {
            year = endOfWeek.year();
        }
        results.push({
            week,
            year,
        });
        step = nextStep;
    }

    return results;
};
export const getWeeksForYears = yearsArray => {
    const startDate = dayjs().year(yearsArray[0]).startOf("year");
    let endDate = dayjs();
    if (yearsArray.length > 1) {
        endDate = endDate.year(yearsArray[yearsArray.length - 1]).endOf("year");
    } else {
        endDate = startDate.endOf("year");
    }
    return getWeeksForRange(startDate, endDate);
};

export const getWeeks = year => {
    const results = [];
    let startDate = dayjs().year(year).startOf("year");
    const endDate = dayjs(startDate).endOf("year");

    let step = dayjs(startDate);
    while (!step.isAfter(endDate)) {
        let week = step.customWeek();
        let year = step.year();
        let nextStep = dayjs(step).add(1, "week");
        if (nextStep.isoWeekday() !== 1) {
            nextStep = nextStep.isoWeekday(1);
        }
        let endOfWeek = step.endOf("isoWeek");
        if (endOfWeek.year() !== year) {
            year = endOfWeek.year();
        }
        results.push({
            week,
            year,
        });
        step = nextStep;
    }
    return results;
};

export const formatToString = date => dayjs(date).format(FULL_DATE_FORMAT);

export const formatToServerString = date =>
    dayjs(date).format(FULL_DATE_SERVER);

export const formatTimeDuration = time => {
    const duration = dayjs.duration({
        hours: time.split(":")[0],
        minutes: time.split(":")[1],
    });
    const result = {
        h: duration.clone().hours(),
        min: duration.clone().minutes(),
    };
    if (!result.h) {
        return `${result.min} min`;
    }
    return `${result.h} h ${result.min ? result.min + " m" : ""}`;
};

export default dayjs;
