import dayjs, { getWeekNumber } from "utils/dateUtils";

import { FULL_DATE_FORMAT } from "constants/dateFormats";

export const getDateInstance = date =>
    date ? dayjs(date, FULL_DATE_FORMAT) : null;

export const checkIfWeekSelected = (fromDate, toDate) => {
    const fromDateWeekNumber = getWeekNumber(fromDate);
    const toDateWeekNumber = getWeekNumber(toDate);
    if (fromDateWeekNumber === toDateWeekNumber) {
        const startOfWeek = getDateInstance(fromDate).startOf("isoWeek");
        const endOfWeek = getDateInstance(fromDate).endOf("isoWeek");
        return (
            getDateInstance(fromDate).isSame(startOfWeek, "day") &&
            getDateInstance(toDate).isSame(endOfWeek, "day")
        );
    }
    return false;
};

export const getLogsParams = (fromDate, toDate, users, projects, weeks) => {
    return {
        from_date: fromDate,
        to_date: toDate,
        users: users.map(item => item.id),
        projects: projects.map(item => item.id),
        weeks,
    };
};

export const getType = status =>
    status === "approved"
        ? "success"
        : status === "declined"
        ? "error"
        : "default";

export const isLastWeek = (date, year) =>
    dayjs(date).customWeek() === dayjs().year(year).endOf("year").customWeek();

export const isFirstWeek = week => week === 1;

export const getCurrentYear = () => dayjs().year();
