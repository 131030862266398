import styled from "styled-components";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

export const StyledGridContainer = styled(Grid)`
    & .MuiGrid-root {
        margin-bottom: 1em;
    }
`;

export const WrapResendButton = styled.div`
    position: relative;
    display: inline-flex;
`;

export const StyledProgressSpinner = styled(CircularProgress)`
    color: #2196f3;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
`;

export const StyledButton = styled(Button)`
    color: #fff;
    align-self: flex-start;
`;

export const StyledGridContainerBottom = styled(Grid)`
    & .MuiGrid-root {
        margin-bottom: 0;
    }
`;
