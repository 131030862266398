// Core
import React, { useState } from "react";
import { useObserver } from "mobx-react";
import { useFormik } from "formik";
// Store
import { useStores } from "store";

// UI
import { TextField, Box, Button, Typography } from "@material-ui/core";
import Link from "components/Link";

// Styles
import { StyledContainer } from "./style";

const useStore = () => {
    const { authStore } = useStores();

    return useObserver(() => ({
        onForgotPassword: authStore.onForgotPassword,
    }));
};

export default function ForgotPassword() {
    const { onForgotPassword } = useStore();
    const [success, setSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async data => {
            await onForgotPassword(data);
            setSuccess(true);
        },
    });

    if (success) {
        return (
            <Box p={4}>
                <Typography variant="h6">
                    Follow link from your mailbox to reset password
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <StyledContainer>
                <form>
                    <TextField
                        id="email"
                        label="Email"
                        onChange={formik.handleChange}
                        defaultValue={formik.values.email}
                    />
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                        mt={3}
                    >
                        <Box>
                            <Link to={"/signin"}>Sign in</Link>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </StyledContainer>
        </>
    );
}
