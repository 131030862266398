import React from "react";
import dayjs from "utils/dateUtils";

import { Box, Typography, CardContent } from "@material-ui/core";
import { TIME_FORMAT, FULL_DATE_FORMAT } from "constants/dateFormats";

import { TypographyBreakWord } from "./style";

const messages = {
    created: " exported report",
    updated: " updated report on the project ",
    deleted: " deleted report on the project ",
};

function ExportHistoryCard({ item }) {
    return (
        <Box component={CardContent} display="flex" flexDirection="column">
            <Typography variant="body1" gutterBottom>
                <b>{`${item.author.firstname} ${item.author.lastname} `}</b>
                {messages[item.event]}
            </Typography>
            {item.entity.filter?.weeks && (
                <Typography variant="caption" color="textSecondary">
                    <i>Weeks: {item.entity.filter?.weeks?.join(", ")}</i>
                </Typography>
            )}
            {item.entity.filter?.from_date && (
                <Typography variant="caption" color="textSecondary">
                    <i>
                        Date:{" "}
                        {`from ${item.entity.filter?.from_date} to ${item.entity.filter?.to_date}`}
                    </i>
                </Typography>
            )}
            {item.entity.filter?.users && (
                <Typography variant="caption" color="textSecondary">
                    <i>User id: {item.entity.filter?.users.join(", ")}</i>
                </Typography>
            )}
            {item.entity.filter?.projects && (
                <Typography variant="caption" color="textSecondary">
                    <i>Project id: {item.entity.filter?.projects.join(", ")}</i>
                </Typography>
            )}
            {item.entity.name && (
                <TypographyBreakWord variant="caption" color="textSecondary">
                    <i>File name: {item.entity.name}</i>
                </TypographyBreakWord>
            )}
            <Typography variant="caption" color="textSecondary">
                <i>
                    Created at:{" "}
                    {dayjs(item.entity.created_at).format(
                        `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                    )}
                </i>
            </Typography>
            <Typography variant="caption" color="textSecondary">
                <i>
                    Updated at:{" "}
                    {dayjs(item.entity.updated_at).format(
                        `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                    )}
                </i>
            </Typography>
        </Box>
    );
}

export default ExportHistoryCard;
