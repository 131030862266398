// Core
import React from "react";

// UI
import { Button } from "@material-ui/core";

// Styles
import { StyledAddIcon } from "./styles";

function AddButton({ handleClick, children }) {
    return (
        <Button
            size="large"
            onClick={handleClick}
            startIcon={<StyledAddIcon />}
        >
            {children}
        </Button>
    );
}

export default AddButton;
