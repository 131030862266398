// Core
import React from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
// UI
import { Grid, TextField, Box } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import FormDialog from "components/FormDialog";
import DialogActionButton from "components/DialogActionButton";

// Styles
import {
    StyledGridContainer,
    WrapResendButton,
    StyledProgressSpinner,
    StyledButton,
    StyledGridContainerBottom,
} from "./styles";

// Utils
import { getInitialFormValues } from "utils/developersUtils";
import RoleSelector from "../RoleSelector";
import ProjectMultiSelect from "../ProjectMultiselect";

let schema = object().shape({
    firstname: string().required(),
    lastname: string().required(),
    email: string().email().required(),
    role: string().required(),
});

export default function CreateUserForm({
    open,
    onClose,
    handleSubmit,
    initialData,
    handleDeleteUser,
    isResendingInvitation,
    onReinviteUser,
    projectList,
}) {
    const initialValues = getInitialFormValues(initialData);
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: schema,
        onSubmit: (data, { resetForm }) => {
            const skillArrayData = { ...data, skills: [data.skills] };

            handleSubmit(skillArrayData, initialData?.id).then(() =>
                resetForm(getInitialFormValues())
            );
        },
    });

    const onCancel = () => {
        formik.resetForm(getInitialFormValues());
        onClose();
    };

    const { errors } = formik;

    return (
        <FormDialog open={open} handleClose={onClose} title="Team member:">
            <form autoComplete="off">
                <StyledGridContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    error={!!errors.firstname}
                                    id="firstname"
                                    label="First name"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.firstname}
                                    autoComplete="off"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <TextField
                                    required
                                    fullWidth
                                    error={!!errors.lastname}
                                    id="lastname"
                                    label="Last name"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.lastname}
                                    autoComplete="off"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                id="country"
                                label="Country"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.country}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                required
                                error={!!errors.email}
                                fullWidth
                                id="email"
                                label="e-mail"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.email}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <RoleSelector
                            value={formik.values.role}
                            error={!!errors.role}
                            onChange={formik.setFieldValue}
                            id="role"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="skills"
                            variant="outlined"
                            label="Skills"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.skills}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectMultiSelect
                            projectList={projectList}
                            onChange={formik.setFieldValue}
                            values={formik.values.projects}
                            id="projects"
                        />
                    </Grid>

                    <StyledGridContainerBottom container alignItems="center">
                        <Grid item xs={12} sm={6}>
                            {initialData && (
                                <WrapResendButton>
                                    <StyledButton
                                        variant="contained"
                                        color="primary"
                                        disabled={isResendingInvitation}
                                        onClick={() =>
                                            onReinviteUser(initialData)
                                        }
                                    >
                                        Resend Invitation
                                    </StyledButton>
                                    {isResendingInvitation && (
                                        <StyledProgressSpinner size={24} />
                                    )}
                                </WrapResendButton>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DialogActions>
                                {initialData && (
                                    <DialogActionButton
                                        onClick={() =>
                                            handleDeleteUser(initialData?.id)
                                        }
                                        type="error"
                                    >
                                        Delete
                                    </DialogActionButton>
                                )}
                                <DialogActionButton
                                    onClick={onCancel}
                                    type="default"
                                >
                                    Cancel
                                </DialogActionButton>
                                <DialogActionButton
                                    onClick={formik.handleSubmit}
                                    variant="contained"
                                    type="success"
                                >
                                    Save
                                </DialogActionButton>
                            </DialogActions>
                        </Grid>
                    </StyledGridContainerBottom>
                </StyledGridContainer>
            </form>
        </FormDialog>
    );
}
