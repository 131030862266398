import React from "react";
import styled from "styled-components";
import { TableRow } from "@material-ui/core";

export const StyledTableRow = styled(({ type, ...otherProps }) => (
    <TableRow {...otherProps} />
))`
    cursor: ${props => (props.hover ? "pointer" : "auto")};
    & .MuiTableCell-body {
        color: ${({ type, theme }) => type && theme.palette.text[type]};
    }

    opacity: ${props => (props.disabled ? 0.4 : 1)};
`;
