// Core
import React from "react";
import dayjs from "utils/dateUtils";
import { useStores } from "store";
import { useObserver } from "mobx-react";

// UI
import { Typography, CardContent, Box } from "@material-ui/core";
import { TIME_FORMAT, FULL_DATE_FORMAT } from "constants/dateFormats";
import DiffViewer from "components/DiffViewer";

// Styles
import { StyledProjectName, TypographyBreakWord, CustomBadge } from "./styles";

const messages = {
    created: "created project",
    updated: "updated project",
    deleted: "deleted project",
    added_user_to_project: " added user the to project",
    deleted_user_to_project: " deleted user from the project",
};

function useStore() {
    const { projectStore } = useStores();

    return useObserver(() => ({
        projectList: projectStore.projectList,
    }));
}

const Caption = ({ children, ...props }) => (
    <TypographyBreakWord variant="caption" color={"textSecondary"} {...props}>
        {children}
    </TypographyBreakWord>
);

function ProjectCard({ item }) {
    const { projectList } = useStore();
    const color = React.useMemo(() => {
        return projectList.find(project => project.id === item.entity.id)
            ?.color;
    }, [item.entity.id, projectList]);
    return (
        <Box component={CardContent} display="flex" flexDirection="column">
            <Typography variant="body1" gutterBottom>
                <b>{`${item.author.firstname} ${item.author.lastname} `}</b>
                {messages[item.event]}
                <StyledProjectName color={color}>
                    {" "}
                    {item.entity.name}
                </StyledProjectName>
            </Typography>
            <Caption>
                <b>{"Name: "}</b>
                {item.changes.name ? (
                    <DiffViewer
                        old={item.oldEntity.name}
                        current={item.entity.name}
                    />
                ) : (
                    item.entity.name
                )}
            </Caption>
            <Caption>
                <b>{"Alias: "}</b>
                {item.changes.alias ? (
                    <DiffViewer
                        old={item.oldEntity.alias}
                        current={item.entity.alias}
                    />
                ) : (
                    item.entity.alias
                )}
            </Caption>
            <Caption gutterBottom>
                <b>{"Description: "}</b>
                {item.changes.descriptions ? (
                    <DiffViewer
                        old={item.oldEntity.descriptions}
                        current={item.entity.descriptions}
                    />
                ) : (
                    ` ${item.entity.descriptions}`
                )}
            </Caption>
            <Caption>
                <b>{"Type: "}</b>
                {item.changes.type ? (
                    <DiffViewer
                        old={item.oldEntity.type}
                        current={item.entity.type}
                    />
                ) : (
                    item.entity.type
                )}
            </Caption>
            <Caption>
                <b>{"Color: "}</b>
                {item.changes.color ? (
                    <>
                        <CustomBadge color={item.oldEntity.color} size={8} />
                        {"->"}
                        <CustomBadge color={item.entity.color} size={8} />
                    </>
                ) : (
                    <CustomBadge color={item.entity.color} size={8} />
                )}
            </Caption>
            {item.event === "created" && (
                <Caption>
                    <i>
                        Created at:{" "}
                        {dayjs(item.entity.created_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Caption>
            )}
            {item.event === "updated" && (
                <Caption>
                    <i>
                        Updated at:{" "}
                        {dayjs(item.entity.updated_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Caption>
            )}
        </Box>
    );
}

export default ProjectCard;
