// Core
import React, { useState } from "react";
import { useFormik } from "formik";

// UI
import { Box, IconButton, TextField, Divider } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DialogActionButton from "components/DialogActionButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

//style

import { StyledListItem } from "./style";

function LogSettingsForm({ logTypes, onSubmit }) {
    const [editingType, setEditingType] = useState(null);

    const formik = useFormik({
        initialValues: {
            type: editingType?.type || "",
        },
        enableReinitialize: true,
        onSubmit: async (data, { resetForm }) => {
            try {
                editingType?.id
                    ? await onSubmit("update", data, editingType.id)
                    : await onSubmit("create", data);
            } finally {
                setEditingType(null);
                resetForm();
            }
        },
    });

    const onCancel = () => {
        setEditingType(null);
        formik.resetForm({ type: "" });
    };

    const onDelete = id => {
        onSubmit("delete", {}, id);
    };

    return (
        <Box>
            <form>
                <Box px={1} pt={2}>
                    <TextField
                        id="type"
                        value={formik.values.type}
                        error={formik.errors.descriptions}
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        label={editingType ? "Edit log type" : "New log type"}
                        variant="outlined"
                    />
                </Box>
                <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <DialogActionButton
                        onClick={onCancel}
                        type="primary"
                        size="small"
                    >
                        Cancel
                    </DialogActionButton>
                    <DialogActionButton
                        onClick={formik.handleSubmit}
                        variant="contained"
                        disabled={formik.values.type.length < 2}
                        type="success"
                        size="small"
                    >
                        Save
                    </DialogActionButton>
                </Box>
            </form>
            <Divider />
            {logTypes.map(item => (
                <StyledListItem
                    key={`list-item-${item.id}`}
                    active={editingType?.id === item.id}
                >
                    {item.type}
                    <Box key={`box-${item.id}`}>
                        <IconButton
                            size="small"
                            key={`editButton-${item.id}`}
                            onClick={() => setEditingType(item)}
                        >
                            <EditOutlinedIcon
                                key={`icon-edit--${item.id}`}
                                color="primary"
                            />
                        </IconButton>
                        <IconButton
                            key={`deleteButton-${item.id}`}
                            size="small"
                            onClick={() => onDelete(item.id)}
                        >
                            <DeleteOutlineIcon
                                key={`icon-delete-${item.id}`}
                                color="secondary"
                            />
                        </IconButton>
                    </Box>
                </StyledListItem>
            ))}
            <Divider />
        </Box>
    );
}

export default LogSettingsForm;
