// Core
import React, { useEffect, useState } from "react";
import { useStores } from "store";
import { useObserver } from "mobx-react";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
// UI
import { Container, Box } from "@material-ui/core";
import { FULL_DATE_SERVER } from "constants/dateFormats";
import ActivitiesList from "./ActivitiesList";

function useStore() {
    const { activityStore, logStore, projectStore } = useStores();

    return useObserver(() => ({
        activities: activityStore.activities,
        onGetActivities: activityStore.onGetActivities,
        nextDate: activityStore.nextDate,
        setInitialState: activityStore.setInitialState,
        logTypes: logStore.logTypes,
        onGetLogTypes: logStore.onGetLogTypes,
        projectList: projectStore.projectList,
        onGetProjectList: projectStore.onGetProjectList,
    }));
}

export default function ReportHistory() {
    const [currentDate] = useState(dayjs().format(FULL_DATE_SERVER));
    const {
        onGetActivities,
        activities,
        nextDate,
        setInitialState,
        onGetLogTypes,
        logTypes,
        onGetProjectList,
    } = useStore();

    useEffect(() => {
        onGetActivities({ date: currentDate });
        return () => {
            setInitialState();
        };
    }, [onGetActivities, currentDate, setInitialState]);

    useEffect(() => {
        onGetProjectList({ per_page: 100, page: 1 });
    }, [onGetProjectList]);

    useEffect(() => {
        async function getLogTypes() {
            await onGetLogTypes();
        }
        getLogTypes();
    }, [logTypes.length, onGetLogTypes]);

    return (
        <Container>
            <Box my={3} width="100%" maxWidth="1024px" mx="auto">
                <InfiniteScroll
                    dataLength={activities.length} //This is important field to render the next data
                    next={() => onGetActivities({ date: nextDate })}
                    hasMore={!!nextDate}
                    loader={<p>Loading...</p>}
                >
                    {activities.map((day, i) => (
                        <ActivitiesList
                            key={`date-${day.date}-${i}`}
                            activities={day.activities}
                            date={day.date}
                        />
                    ))}
                </InfiniteScroll>
            </Box>
        </Container>
    );
}
