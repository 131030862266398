import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledContainer = styled(Paper)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: bottom left;
    padding: 20px;
    width: 90%;
    max-width: 320px;
    & form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const StyledLink = styled(NavLink)`
    ${({ theme }) => `
    text-decoration: none;
    color: ${theme.palette.primary.dark};
    &.active {
        color: ${theme.palette.grey["700"]};
    }
  `}
`;
