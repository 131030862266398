export const TABLE_HEADERS = [
    { name: "Created", isDisabled: false },
    { name: "Updated", isDisabled: false },
    { name: "Project name", isDisabled: false },
    { name: "Developers", isDisabled: true },
    { name: "Type", isDisabled: false },
    { name: "Unapproved time", isDisabled: true },
    { name: "Approved time", isDisabled: false },
];

export const DEFAULT_FORM_VALUES = {
    alias: "",
    name: "",
    descriptions: "",
    users: [],
    type: "",
    color: null,
};
