import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import { TableContainer } from "@material-ui/core";

export const StyledSup = styled.sup`
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledAvatar = styled(Avatar)``;

export const StyledTableContainer = styled(TableContainer)`
    & .MuiAvatar-root {
        background-color: ${({ theme }) => theme.palette.logo};
        cursor: pointer;
        width: ${({ theme }) => theme.spacing(4)}px;
        height: ${({ theme }) => theme.spacing(4)}px;
        ${({ theme }) => theme.typography.body1}
        text-transform: uppercase;
    }
`;
