// Core
import React from "react";
// UI
import { Box, Typography } from "@material-ui/core";

// Utils
import { getStartOfWeek, getEndOfWeek } from "utils/dateUtils";

// constants
import { DATE_AND_MONTH } from "constants/dateFormats";
import TotalTime from "../TotalWeekTime";

export default function TotalTimeInfo({
    totalDayTime,
    totalWeekTime,
    selectedWeek,
}) {
    return (
        <>
            {selectedWeek && (
                <Box display="flex">
                    <Box pl={1}>
                        <Typography
                            align="right"
                            variant="subtitle1"
                            gutterBottom
                            color="textPrimary"
                        >
                            <b>{"From "}</b>
                            {getStartOfWeek(selectedWeek).format(
                                DATE_AND_MONTH
                            )}
                            <b>{" to "}</b>
                            {getEndOfWeek(selectedWeek).format(DATE_AND_MONTH)}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box display="flex">
                <Box display="flex" pl={1}>
                    <TotalTime
                        totalTime={totalWeekTime}
                        title="Total week work time:"
                    />
                </Box>
            </Box>
            <Box display="flex">
                <TotalTime
                    totalTime={totalDayTime}
                    title="Total day work time:"
                />
            </Box>
        </>
    );
}
