import React from "react";
import dayjs from "utils/dateUtils";
import { Box, Typography, CardContent } from "@material-ui/core";
import { TIME_FORMAT, FULL_DATE_FORMAT } from "constants/dateFormats";
import DiffViewer from "components/DiffViewer";

const messages = {
    created: " created work item type ",
    updated: " updated work item type ",
    deleted: " deleted work item type ",
};

function LogTypeCard({ item }) {
    return (
        <Box component={CardContent} display="flex" flexDirection="column">
            <Typography variant="body1" gutterBottom>
                <b>{`${item.author.firstname} ${item.author.lastname} `}</b>
                {messages[item.event]}
                <b>
                    {item.changes.type ? (
                        <DiffViewer
                            old={item.oldEntity.type}
                            current={item.entity.type}
                        />
                    ) : (
                        item.entity.type
                    )}
                </b>
            </Typography>
            {item.event === "created" && (
                <Typography variant="caption" color="textSecondary">
                    <i>
                        Created at:{" "}
                        {dayjs(item.entity.created_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Typography>
            )}
            {item.event === "updated" && (
                <Typography variant="caption" color="textSecondary">
                    <i>
                        Updated at:{" "}
                        {dayjs(item.entity.updated_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Typography>
            )}
        </Box>
    );
}

export default LogTypeCard;
