// Core
import React, { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";

// UI
import { Box, Typography } from "@material-ui/core";
import { DAY_FORMAT, FULL_DATE_FORMAT } from "constants/dateFormats";

// Styles
import { StyledButton } from "./styles";

// Utils
import { getMonthNameByWeek } from "utils/developerReportUtils";

function checkLogDate(logs, date) {
    return logs.some(item =>
        dayjs(item.date, FULL_DATE_FORMAT).isSame(
            dayjs(date, FULL_DATE_FORMAT),
            "day"
        )
    );
}

const fillWeek = (startDate, endDate) => {
    let results = [];
    let step = dayjs(startDate);
    while (step.isBefore(endDate)) {
        results.push(dayjs(step));
        step = step.add(1, "day");
    }
    return results;
};

const getWeekDates = (week, year) => {
    let date = dayjs().set("year", year);
    date = date.customWeek(week, year);
    date = date.startOf("isoWeek").set("hours", 0).set("minutes", 1);

    const startDate = dayjs(date);
    const endDate = dayjs(startDate).add(7, "days");
    return fillWeek(startDate, endDate);
};

export default function ({
    selectedWeek,
    logs,
    setDay,
    selectedDay,
    declinedLogs,
}) {
    const [weekDates, setWeekDates] = useState([]);

    useEffect(() => {
        if (!selectedWeek) {
            setWeekDates([]);
            return;
        }

        setWeekDates(getWeekDates(selectedWeek.week, selectedWeek.year));
    }, [selectedWeek]);

    const isIncludeRejectedDay = useCallback(
        weekDay => {
            const formattedDate = weekDay.format("DD-MM-YYYY");
            return declinedLogs.find(({ date }) => formattedDate === date);
        },
        [declinedLogs]
    );

    const formatDate = day => dayjs(day).format(DAY_FORMAT);
    const isActive = day => dayjs(day).isSame(selectedDay, "day");

    return (
        selectedWeek && (
            <Box display="flex" flexWrap="wrap">
                {weekDates.map(day => {
                    const hasLogs = checkLogDate(logs, day);
                    return (
                        <Box
                            key={`box-${day}`}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <StyledButton
                                size="large"
                                onClick={() => setDay(day)}
                                isActive={isActive(day)}
                                hasLogs={hasLogs}
                                isRejected={isIncludeRejectedDay(day)}
                                variant="contained"
                                key={`date-${day}`}
                            >
                                {formatDate(day)}
                            </StyledButton>
                            <Typography
                                key={`caption-${day}`}
                                variant="caption"
                                color={
                                    day.day() === 6 || day.day() === 0
                                        ? "secondary"
                                        : "textPrimary"
                                }
                            >
                                {day.format("ddd")}
                            </Typography>
                        </Box>
                    );
                })}
                <Box width="100%" ml={1}>
                    <Typography variant="subtitle1">
                        {selectedWeek.week && getMonthNameByWeek(selectedWeek)}
                    </Typography>
                </Box>
            </Box>
        )
    );
}
