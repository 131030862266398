// Core
import React, { useState, useEffect, useCallback } from "react";
import { useObserver } from "mobx-react";
// UI
import { Grid, Container, Typography, Box, Tabs, Tab } from "@material-ui/core";
import ProjectsTable from "./ProjectsTable";
import CreateProjectForm from "./CreateProjectForm";
import AddButton from "components/AddButton";
import StyledPaper from "components/StyledPaper";

// Utils
import { useStores } from "../../store";

// Styled
import { StyledGrid } from "./styles";
import ReportPagination from "components/ReportPagination";

const TABS = [
    { id: "active", title: "Active", Component: null },
    { id: "inactive", title: "Archived", Components: null },
];

function useStore() {
    const { projectStore, loadersStore, paginationStore } = useStores();

    return useObserver(() => ({
        projectList: projectStore.projectList,
        projectTypes: projectStore.projectTypes,
        userList: projectStore.userList,
        onGetProjectList: projectStore.onGetProjectList,
        onGetUserList: projectStore.onGetUserList,
        onCreateProject: projectStore.onCreateProject,
        onUpdateProject: projectStore.onUpdateProject,
        onDeleteProject: projectStore.onDeleteProject,
        onGetProjectTypes: projectStore.onGetProjectTypes,
        setEditingProjectData: projectStore.setEditingProjectData,
        editingProjectData: projectStore.editingProjectData,
        setLoadingPage: loadersStore.setLoadingPage,
        page: paginationStore.page,
        perPage: paginationStore.perPage,
    }));
}

export default function Projects() {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [sort, setSort] = useState({ field: "", type: "" });

    const {
        projectList,
        projectTypes,
        userList,
        onCreateProject,
        onUpdateProject,
        onGetProjectList,
        onGetProjectTypes,
        onGetUserList,
        setEditingProjectData,
        editingProjectData,
        onDeleteProject,
        page,
        perPage,
    } = useStore();

    const [selectedTab, setValue] = useState(0);
    const handleChange = (event, index) => {
        setValue(index);
    };

    useEffect(() => {
        onGetUserList();
        onGetProjectTypes();
    }, [onGetUserList, onGetProjectTypes]);

    const updateProjects = useCallback(() => {
        onGetProjectList({
            page,
            per_page: perPage,
            sort,
            filter: { status: TABS[selectedTab].id },
        });
    }, [onGetProjectList, page, perPage, selectedTab, sort]);

    useEffect(() => {
        updateProjects();
    }, [updateProjects]);

    useEffect(() => {
        if (editingProjectData) {
            showModal();
        }
    }, [editingProjectData]);

    const showModal = () => {
        setModalVisibility(true);
    };

    const closeModal = () => {
        setModalVisibility(false);
        setEditingProjectData();
    };

    const handleDeleteProject = async id => {
        await onDeleteProject(id);
        closeModal();
    };

    const onSubmitProject = async (data, id) => {
        editingProjectData
            ? await onUpdateProject(data, id)
            : await onCreateProject(data);
        updateProjects();
        closeModal();
    };

    const onEditProject = data => {
        setEditingProjectData(data);
    };

    const onSort = field => () => {
        if (sort.type === "desc") {
            return setSort({ field: "", type: "" });
        }

        return setSort({ field, type: sort.field ? "desc" : "asc" });
    };

    return (
        <Container>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                centered
            >
                {TABS.map(({ id, title }) => (
                    <Tab key={id} label={title} />
                ))}
            </Tabs>
            <Grid container>
                <StyledGrid item xs={12}>
                    <Typography variant="h6">Available projects:</Typography>
                </StyledGrid>
                <Grid item xs>
                    <AddButton handleClick={showModal}>
                        Create project
                    </AddButton>
                </Grid>
                <Grid item xs={12}>
                    <Box pb={5}>
                        <StyledPaper>
                            <ProjectsTable
                                onSort={onSort}
                                sort={sort}
                                projectList={projectList}
                                setEditingProjectData={onEditProject}
                            />
                            {projectList?.length > 0 && <ReportPagination />}
                        </StyledPaper>
                    </Box>
                </Grid>
            </Grid>
            <CreateProjectForm
                open={isModalVisible}
                onClose={closeModal}
                userList={userList}
                projectTypes={projectTypes}
                handleSubmit={onSubmitProject}
                initialData={editingProjectData}
                handleDeleteProject={handleDeleteProject}
            />
        </Container>
    );
}
