// Core
import { observable, action, decorate, runInAction } from "mobx";
import logService from "services/api/LogService";
import projectService from "services/api/ProjectService";
import userService from "services/api/UserService";

// Constants
import { MAX_PER_PAGE } from "constants/pagination";

// Utils
import { distributeBy } from "./ActivityStore";

const updateLogListStrategy = {
    update: (dataArray, newItem) =>
        dataArray.map(item => (item.id === newItem.id ? newItem : item)),
    delete: (dataArray, id) => {
        return dataArray.filter(item => item.id !== id);
    },
    create: (userList, item) => {
        item.status = "in review";
        return [...userList, item];
    },
};

class LogStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.logs = [];
        this.logTypes = [];

        this.projectList = [];
        this.userList = [];
        this.declinedLogs = [];

        this.historyByLog = [];
        this.historyNextDate = null;
        this.isLoadingHistoryByLog = false;
    }

    setInitialState = () => {
        this.logs = [];
        this.logTypes = [];
        this.projectList = [];
        this.userList = [];
    };

    setPaginationMeta = data => {
        this.rootStore.paginationStore.setMeta(data);
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    setLoading(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    setEmptyLogs = () => {
        this.logs = [];
    };

    getHistoryByLog = async (id, params = {}) => {
        try {
            this.isLoadingHistoryByLog = true;
            const response = await logService.getLogHistory(id, params);

            this.historyByLog = this.historyByLog.concat([
                {
                    date: response?.data?.current_date,
                    activities: distributeBy("tree_id", response?.data?.data),
                },
            ]);

            this.historyNextDate = response?.data?.next_date;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoadingHistoryByLog = false;
        }
    };

    clearHistoryByLog = () => {
        this.historyByLog = [];
        this.historyNextDate = null;
    };

    onGetLogs = async params => {
        try {
            this.setLoading(true);
            const response = await logService.getLogs(params);
            runInAction("setLogList", () => {
                this.logs = response?.data?.data;
            });
            this.setPaginationMeta(response?.data?.meta);
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onGetDeclinedLogs = async params => {
        try {
            this.setLoading(true);
            const response = await logService.getLogs({
                ...params,
                status: ["declined"],
            });
            runInAction("setDeclinedLogList", () => {
                this.declinedLogs = response?.data?.data;
            });
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onAddLog = async params => {
        try {
            this.setLoading(true);
            const response = await logService.addLog(params);
            runInAction("addLogItem", () => {
                this.logs = updateLogListStrategy["create"](
                    this.logs,
                    response?.data?.data
                );
            });
            this.showNotification("success", "Log saved");
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onUpdateLog = async (params, pathId) => {
        try {
            this.setLoading(true);
            const response = await logService.updateLog(params, pathId);

            runInAction("updateLogItem", () => {
                this.logs = updateLogListStrategy["update"](
                    this.logs,
                    response?.data?.data
                );
            });
            this.showNotification("success", "Log saved");
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onUpdateLogStatus = async params => {
        try {
            this.setLoading(true);
            const response = await logService.setLogStatus(params);
            runInAction("updateLogStatusItem", () => {
                this.logs = updateLogListStrategy["update"](
                    this.logs,
                    response?.data?.data
                );
            });
            this.showNotification("success", "Log status changed");
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onDeleteLog = async id => {
        try {
            this.setLoading(true);
            await logService.deleteLog(id);
            runInAction("selectLogItem", () => {
                this.logs = updateLogListStrategy["delete"](this.logs, id);
            });
            this.showNotification("success", "Log deleted");
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };

    onGetLogTypes = async params => {
        try {
            this.setLoading(true);
            const { data } = await logService.getLogTypes({
                ...params,
                per_page: MAX_PER_PAGE,
            });
            runInAction("setLogTypes", () => {
                this.logTypes =
                    data?.data && data?.data.sort((a, b) => b.id - a.id);
            });
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setLoading(false);
        }
    };
    onCreateLogType = async body => {
        try {
            this.setLoading(true);
            const { data } = await logService.addLogType(body);
            runInAction("updateLogTypes", () => {
                this.logTypes.unshift(data?.data);
            });
            this.showNotification("success", "Log type saved");
        } catch (err) {
            this.showNotification(
                "error",
                err?.response?.data?.errors?.type[0]
            );
            throw err;
        } finally {
            this.setLoading(false);
        }
    };
    onUpdateLogType = async (body, id) => {
        try {
            this.setLoading(true);
            const {
                data: { data },
            } = await logService.updateLogType(body, id);
            this.showNotification("success", "Log type changed");
            runInAction("updateLogTypes", () => {
                this.logTypes = this.logTypes.map(item => {
                    if (item.id === data.id) {
                        return data;
                    }
                    return item;
                });
            });
        } catch (err) {
            this.showNotification(
                "error",
                err?.response?.data?.errors?.type[0]
            );
            throw err;
        } finally {
            this.setLoading(false);
        }
    };
    onDeleteLogType = async id => {
        try {
            this.setLoading(true);
            await logService.deleteLogType(id);
            this.showNotification("success", "Log type deleted");
            runInAction("updateLogTypes", () => {
                this.logTypes = this.logTypes.filter(item => item.id !== id);
            });
        } catch (err) {
            this.showNotification(
                "error",
                err?.response?.data?.errors?.type[0]
            );
            throw err;
        } finally {
            this.setLoading(false);
        }
    };
    onGetProjectList = async () => {
        try {
            this.setLoading(true);
            const projectList = await projectService.getProjectList({
                per_page: MAX_PER_PAGE,
            });
            runInAction("setProjectList", () => {
                this.projectList = projectList?.data?.data;
            });
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            console.error(error);
        } finally {
            this.setLoading(false);
        }
    };
    onGetUserList = async () => {
        try {
            this.setLoading(true);
            const userList = await userService.getUserList({
                per_page: MAX_PER_PAGE,
            });
            runInAction("setUserList", () => {
                this.userList = userList?.data?.data;
            });
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            console.error(error);
        } finally {
            this.setLoading(false);
        }
    };

    set logTypes(data) {
        this.logTypes = data;
    }

    set logs(logs) {
        this.logs = logs;
    }
}

decorate(LogStore, {
    // state
    logs: observable,
    logTypes: observable,
    projectList: observable,
    userList: observable,
    declinedLogs: observable,
    isLoadingHistoryByLog: observable,
    historyByLog: observable,
    historyNextDate: observable,

    // actions
    onGetLogs: action,
    onAddLog: action,
    onUpdateLog: action,
    onDeleteLog: action,
    onUpdateLogStatus: action,
    updateLogInList: action,
    setEmptyLogs: action,
    setLogTypes: action,
    onGetLogTypes: action,
    onGetProjectList: action,
    onGetUserList: action,
    onCreateLogType: action,
    onUpdateLogType: action,
    onDeleteLogType: action,
    setInitialState: action,
    onGetDeclinedLogs: action,
    getHistoryByLog: action,
    clearHistoryByLog: action,
});

export default LogStore;
