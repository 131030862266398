import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const CustomBadge = styled.div(
    ({ size, color }) => `
      display: table;
      width: ${size}px;
      height: ${size}px;
      border-radius: 50%;
      margin-right: 5px;
      background: ${color};
`
);

export const Title = styled.span(
    ({
        fontSize,
        theme: {
            palette: {
                default: { main },
            },
        },
        color = main,
    }) => `
  color: ${color};
  line-height: ${fontSize}px;
  font-size: ${fontSize}px;
  overflow: hidden;
  text-overflow: ellipsis;
`
);
