import React from "react";
import dayjs from "utils/dateUtils";
import { useObserver } from "mobx-react";

// components
import { Box, Typography, CardContent } from "@material-ui/core";
import DiffViewer from "components/DiffViewer";

// constants
import { TIME_FORMAT, FULL_DATE_FORMAT } from "constants/dateFormats";

// utils
import { formatFromSeconds } from "utils/developersUtils";
import { useStores } from "store";

// styles
import { StyledProjectName } from "./style";

const defineActionDescription = item => {
    const actionsEnum = {
        descriptions: "updated description",
        status: `${item.entity.status} work item`,
        total: "changed working hours",
        log_type: "changed log type",
        work_type: "changed work type",
        project_id: "changed project in work item",
    };
    const results = [];
    Object.keys(item.changes).forEach(key => {
        if (actionsEnum[key]) {
            results.push(actionsEnum[key]);
        }
    });
    return results.join(", ");
};

const Caption = ({ children, ...props }) => (
    <Typography variant="caption" color={"textSecondary"} {...props}>
        {children}
    </Typography>
);

function useStore() {
    const { logStore, projectStore } = useStores();

    return useObserver(() => ({
        logTypes: logStore.logTypes,
        onGetLogTypes: logStore.onGetLogTypes,
        projectList: projectStore.projectList,
    }));
}

function LogCard({ item }) {
    const { logTypes, projectList } = useStore();

    const logType = logTypes.find(({ id }) => item.entity?.log_type_id === id);
    const oldLogType = logTypes.find(
        ({ id }) => item.oldEntity?.log_type_id === id
    );

    const color = React.useMemo(() => {
        return projectList.find(
            project => project.id === item.entity.project.id
        )?.color;
    }, [item.entity.project.id, projectList]);

    return (
        <Box component={CardContent} display="flex" flexDirection="column">
            <Typography variant="body1" gutterBottom>
                <b>{`${item.author.firstname} ${item.author.lastname} `}</b>
                {item.event === "updated" ? defineActionDescription(item) : ""}
                {item.event === "created" ? "created work item" : ""}
                {item.event === "deleted" ? "deleted work item" : ""}
            </Typography>
            <Caption>
                <i>
                    <b>{"Project: "}</b>
                    <StyledProjectName color={color}>
                        {item.changes.project_id ? (
                            <DiffViewer
                                old={item.oldEntity.project?.name}
                                current={item.entity.project?.name}
                            />
                        ) : (
                            item.entity.project?.name
                        )}
                    </StyledProjectName>
                </i>
            </Caption>
            <Caption gutterBottom>
                <b>{"Description: "}</b>
                {item.changes.descriptions ? (
                    <DiffViewer
                        old={item.oldEntity.descriptions}
                        current={item.entity.descriptions}
                    />
                ) : (
                    item.entity.descriptions
                )}
            </Caption>
            <Box>
                <Caption>
                    <b>{"From "}</b>
                    {item.changes.from_time ? (
                        <DiffViewer
                            old={item.oldEntity.from_time}
                            current={item.entity.from_time}
                        />
                    ) : (
                        item.entity.from_time
                    )}
                </Caption>{" "}
                <Caption>
                    <b>{"to "}</b>
                    {item.changes.to_time ? (
                        <DiffViewer
                            old={item.oldEntity.to_time}
                            current={item.entity.to_time}
                        />
                    ) : (
                        item.entity.to_time
                    )}
                </Caption>
            </Box>
            <Caption>
                <b>{"Total time: "}</b>
                {item.changes.total ? (
                    <DiffViewer
                        old={formatFromSeconds(item.oldEntity.total)}
                        current={formatFromSeconds(item.entity.total)}
                    />
                ) : (
                    formatFromSeconds(item.entity.total)
                )}
            </Caption>
            <Caption>
                <i>
                    <b>{"Status: "}</b>
                    {item.changes.status ? (
                        <DiffViewer
                            old={item.oldEntity.status}
                            current={item.entity.status}
                        />
                    ) : (
                        item.entity.status
                    )}
                </i>
            </Caption>
            <Caption>
                <i>
                    <b>{"Log type: "}</b>
                    {item.changes.log_type_id ? (
                        <DiffViewer
                            old={oldLogType?.type}
                            current={logType?.type}
                        />
                    ) : (
                        logType?.type
                    )}
                </i>
            </Caption>
            {item.entity.work_type && (
                <Caption>
                    <i>
                        <b>{"Work type: "}</b>
                        {item.changes.work_type ? (
                            <DiffViewer
                                old={item.oldEntity.work_type}
                                current={item.entity.work_type}
                            />
                        ) : (
                            item.entity.work_type
                        )}
                    </i>
                </Caption>
            )}
            {item.event === "created" && (
                <Caption>
                    <i>
                        Created at:{" "}
                        {dayjs(item.entity.created_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Caption>
            )}
            {item.event === "updated" && (
                <Caption>
                    <i>
                        Updated at:{" "}
                        {dayjs(item.entity.updated_at).format(
                            `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                        )}
                    </i>
                </Caption>
            )}
        </Box>
    );
}

export default LogCard;
