import styled from "styled-components";

import FormDialog from "components/FormDialog";

export const StyledFormDialog = styled(FormDialog)`
    & .MuiPaper-root,
    .MuiDialogContent-root {
        overflow: hidden;
        min-height: 50vh;
    }
`;
