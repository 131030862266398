// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class AuthService {
    login = async data => {
        return axios.post(`${API_URL}/auth/login`, {
            ...data,
        });
    };

    logout = async () => axios.post(`${API_URL}/auth/logout`);

    forgotPassword = async body =>
        axios.post(`${API_URL}/auth/forgot-password`, { ...body });

    resetPassword = body =>
        axios.post(`${API_URL}/auth/reset-password`, { ...body });

    getMe = async () => axios.get(`${API_URL}/me`);

    addAuthHeaderInterceptor = async ({ token, onError }) => {
        return axios.interceptors.request.use(
            config => {
                if (token) {
                    config.headers["Authorization"] = "Bearer " + token;
                }
                return config;
            },
            error => {
                if (error.response.status === 401) {
                    onError();
                }
                Promise.reject(error);
            }
        );
    };

    clearInterceptor = interceptor => {
        axios.interceptors.request.eject(interceptor);
    };
}

const authService = new AuthService();

export default authService;
