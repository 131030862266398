// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class UserService {
    getUserList = params => axios.get(`${API_URL}/user`, { params });
    getMe = () => axios.get(`${API_URL}/me`);
    getUser = id => axios.get(`${API_URL}/user/${id}`);
    createUser = body => axios.post(`${API_URL}/user`, { ...body });
    updateUser = (body, pathId) =>
        axios.put(`${API_URL}/user/${pathId}`, { ...body });
    deleteUser = id => axios.delete(`${API_URL}/user/${id}`);
    uploadAvatar = body =>
        axios.post(`${API_URL}/user/avatar`, body, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    resendInvitationUser = id =>
        axios.get(`${API_URL}/user/${id}/resend-credentials`);
}
const userService = new UserService();

export default userService;
