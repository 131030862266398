// Core
import React from "react";

// UI
import {
    TableHead,
    TableBody,
    Table,
    Typography,
    Tooltip,
    Box,
    TableSortLabel,
} from "@material-ui/core";
import TableRow from "components/TableRow";
import TableCell from "components/TableCell";
import CustomAvatarGroup from "components/CustomAvatarGroup";

// Constants
import { TABLE_HEADERS } from "constants/projects";
import { StyledTableCell } from "components/TableCell/styles";
import ColoredName from "components/ColoredName";

// Utils
import { getDate, getTime } from "utils/projectUtils";
import { formatFromSeconds } from "utils/developersUtils";
import { getUserAvatar } from "utils/developersUtils";

// Styles
import { StyledAvatar, StyledTableContainer } from "./style";

export default function ({ projectList, setEditingProjectData, sort, onSort }) {
    return (
        <StyledTableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {TABLE_HEADERS.map(({ name, isDisabled }) => (
                            <TableCell key={name}>
                                <TableSortLabel
                                    disabled={isDisabled}
                                    active={sort.field === name}
                                    direction={sort.type || "asc"}
                                    onClick={onSort(name)}
                                >
                                    {name}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projectList.length ? (
                        projectList.map(project => (
                            <TableRow
                                hover
                                key={project.id}
                                onClick={() => setEditingProjectData(project)}
                                disabled={"inactive" === project?.status}
                            >
                                <TableCell>
                                    <Typography variant="caption">
                                        {getDate(project.created_at)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        {getTime(project.updated_at)}
                                    </Typography>{" "}
                                    <Typography variant="caption">
                                        {getDate(project.updated_at)}
                                    </Typography>
                                </TableCell>
                                <Box component={TableCell} maxWidth="100px">
                                    <ColoredName
                                        color={project.color}
                                        size={15}
                                        text={project.name}
                                    />
                                </Box>
                                <Box component={TableCell}>
                                    <CustomAvatarGroup
                                        max={10}
                                        extraAvatarsTooltipTitle={[
                                            ...project.users,
                                        ]
                                            .splice(10)
                                            .map(
                                                user =>
                                                    `${user.firstname} ${user.lastname}`
                                            )
                                            .join(", ")}
                                    >
                                        {project.users.map((user, i) => (
                                            <Tooltip
                                                title={`${user.firstname} ${user.lastname}`}
                                                placement="top"
                                                key={`title-${user.firstname} ${user.lastname}`}
                                            >
                                                <StyledAvatar
                                                    src={getUserAvatar(
                                                        user.avatar
                                                    )}
                                                    key={`avatar-${user.firstname} ${user.lastname}`}
                                                >
                                                    {`${
                                                        user?.firstname[0] || ""
                                                    }${
                                                        user?.lastname[0] || ""
                                                    }`}
                                                </StyledAvatar>
                                            </Tooltip>
                                        ))}
                                    </CustomAvatarGroup>
                                </Box>
                                <TableCell>{project.type}</TableCell>
                                <StyledTableCell>
                                    {formatFromSeconds(
                                        project.total_log -
                                            project.approved_time
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {formatFromSeconds(project.approved_time)}
                                </StyledTableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <Box p={3} component="td">
                                {"Empty"}
                            </Box>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
}
