import styled from "styled-components";
import GetAppIcon from "@material-ui/icons/GetApp";

export const StyledIcon = styled(GetAppIcon)`
    && {
        font-size: ${({ size }) => size};
    }
    color: ${({ disabled, theme }) =>
        disabled ? theme.palette.grey[300] : theme.palette.info.main};
`;
