// Core
import { observable, action, decorate, runInAction } from "mobx";
import userService from "../services/api/UserService";
import intersection from "lodash.intersection";

const fetchStrategy = ({ body, id }) => ({
    delete: () => userService.deleteUser(id),
    update: () => userService.updateUser(body, id),
    create: () => userService.createUser(body),
});

class UserStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        this.userList = [];
        this.editingUserData = undefined;
        this.isResendingInvitation = false;
    }

    setPaginationMeta = data => {
        this.rootStore.paginationStore.setMeta(data);
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    onAddUsersToProject = (body, id) =>
        this.rootStore.projectStore.onAddUsersToProject(body, id);
    onDeleteUsersFromProject = (body, id) =>
        this.rootStore.projectStore.onDeleteUsersFromProject(body, id);

    setIsLoading(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    onGetUserList = async params => {
        try {
            this.setIsLoading(true);
            const response = await userService.getUserList(params);
            runInAction("setUserList", () => {
                this.userList = response?.data?.data;
            });
            this.setPaginationMeta(response?.data?.meta);
        } catch (err) {
            this.showNotification("error", err?.response?.data?.message);
            throw err;
        } finally {
            this.setIsLoading(false);
        }
    };

    handleFetchUserData = async (actionType, data) => {
        try {
            this.setIsLoading(true);
            const response = await fetchStrategy(data)[actionType]();
            this.showNotification("success", "User saved");
            return response;
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setIsLoading(false);
        }
    };

    onCreateUser = async body => {
        const projects = [...body.projects];
        delete body.projects;
        const {
            data: { data },
        } = await this.handleFetchUserData("create", { body });
        await Promise.all([
            ...projects.map(projectId =>
                this.onAddUsersToProject({ users: [data.id] }, projectId)
            ),
        ]);
    };

    updateUsersForProject = async (projects, id) => {
        const intersectedArray = intersection(
            projects,
            this.editingUserData.projects
        );
        const deleted = this.editingUserData.projects.filter(
            id => !intersectedArray.includes(id)
        );
        const added = projects.filter(id => !intersectedArray.includes(id));
        await Promise.all([
            ...added.map(projectId =>
                this.onAddUsersToProject({ users: [id] }, projectId)
            ),
        ]);
        await Promise.all([
            ...deleted.map(projectId =>
                this.onDeleteUsersFromProject({ users: [id] }, projectId)
            ),
        ]);
    };

    onUpdateUser = async (body, id) => {
        const projects = [...body.projects];
        delete body.projects;
        await this.updateUsersForProject(projects, id);
        await this.handleFetchUserData("update", { body, id });
    };

    onDeleteUser = id => this.handleFetchUserData("delete", { id });

    setEditingUserData = user => {
        this.editingUserData = user;
    };

    onResendInvitationUser = async id => {
        try {
            this.isResendingInvitation = true;
            await userService.resendInvitationUser(id);

            this.showNotification("success", "Invitation has been sent");
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.isResendingInvitation = false;
        }
    };
}

decorate(UserStore, {
    // state
    userList: observable,
    editingUserData: observable,
    isResendingInvitation: observable,

    // actions
    setEditingUserData: action,
    onGetUserList: action,
    onCreateUser: action,
    onUpdateUser: action,
    onDeleteUser: action,
    onResendInvitationUser: action,
});

export default UserStore;
