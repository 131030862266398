import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledLink = styled(NavLink)`
    ${({ theme }) => `
    text-decoration: none;
    color: ${theme.palette.primary.dark};
    &.active {
        color: ${theme.palette.grey["700"]};
    }
  `}
`;

export default StyledLink;
