// Core
import React from "react";

// UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ProfileMenu from "components/ProfileMenu";
import Link from "components/Link";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

// Components
import NavigationMenu from "./NavigationMenu";

// UI
import Logo from "components/Logo";

// Utils
import { getRoutesByRole } from "../../utils/routerUtils";
import { Divider } from "@material-ui/core";

// Hooks
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    pointer: {
        cursor: "pointer",
    },
}));

const NavigationPanel = ({ me, onLogout }) => {
    const classes = useStyles();

    const routes = getRoutesByRole(me?.role);

    return (
        <AppBar position="static">
            <Toolbar>
                <Logo />

                <Typography variant="h6" className={classes.title} />
                <Hidden smDown>
                    <Box mr={4} display="flex" alignItems="center">
                        {routes.map(route => (
                            <Box px={1} key={`box-${route.name}`}>
                                <Typography
                                    variant="button"
                                    key={`typo-${route.name}`}
                                    noWrap
                                >
                                    <Link
                                        to={route.path}
                                        key={`link-${route.name}`}
                                    >
                                        {route.name}
                                    </Link>
                                </Typography>
                            </Box>
                        ))}
                        <Divider orientation="vertical" flexItem />
                        {me && (
                            <Box p={2} className={classes.pointer}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    onClick={onLogout}
                                >
                                    Sign out
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Hidden>
                {me && <ProfileMenu me={me} onLogout={onLogout} />}
                {me && (
                    <Hidden mdUp>
                        <NavigationMenu
                            menuItems={routes}
                            me={me}
                            onLogout={onLogout}
                        />
                    </Hidden>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationPanel;
