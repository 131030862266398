export const TABLE_HEADERS = [
    "Date",
    "Week",
    "Developer",
    "Project name",
    "Description",
    "Work type",
    "Work time",
    "Last generated",
    "Status",
];

export const STATUS_OPTIONS = [
    {
        value: "approved",
        label: "Approve",
    },
    {
        value: "declined",
        label: "Decline",
    },
];

export const STATUS_COLORS = {
    approved: "success",
    declined: "error",
};

export const WORK_TYPES = [
    { label: "In Scope", value: "in scope" },
    { label: "Change request", value: "change", color: "blue" },
    { label: "Bug", value: "bug", color: "red" },
];
