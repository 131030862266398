// Core
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useObserver } from "mobx-react";

// UI
import FilterSelectorPanel from "./FilterSelectorPanel";
import { Container, Box } from "@material-ui/core";
import LogTableReports from "./LogReportTable";
import ReportPagination from "components/ReportPagination";
import DownloadButton from "components/DownloadButton";
import TotalTime from "./TotalTime";
import { INITIAL_VALUE } from "./WeekSelector";

// Store
import { useStores } from "store";

// Utils
import { formatToServerString } from "utils/dateUtils";
// Styles
import { StyledPaper } from "./style";
import WorkItemAddEditForm from "../../../components/WorkItemAddEditForm";

function useStore() {
    const { logStore, exportStore, paginationStore } = useStores();

    return useObserver(() => ({
        logs: logStore.logs,
        setEmptyLogs: logStore.setEmptyLogs,
        projectList: logStore.projectList,
        userList: logStore.userList,
        onGetLogs: logStore.onGetLogs,
        onGetProjectList: logStore.onGetProjectList,
        onGetUserList: logStore.onGetUserList,
        onUpdateLogStatus: logStore.onUpdateLogStatus,
        onDownload: exportStore.onDownload,
        page: paginationStore.page,
        perPage: paginationStore.perPage,
        setInitialPagination: paginationStore.setInitialValues,
        logTypes: logStore.logTypes,
        onGetDeclinedLogs: logStore.onGetDeclinedLogs,
        onGetLogTypes: logStore.onGetLogTypes,
        onUpdateLog: logStore.onUpdateLog,
        onGetTotalLogTime: exportStore.onGetTotalLogTime,
        totalLogTime: exportStore.totalLogTime,
    }));
}

export default function ManagerReports() {
    const {
        logs,
        projectList,
        onGetLogs,
        userList,
        page,
        perPage,
        onDownload,
        onGetUserList,
        onGetLogTypes,
        onGetProjectList,
        setInitialPagination,
        setEmptyLogs,
        logTypes,
        onUpdateLog,
        totalLogTime,
        onGetTotalLogTime,
    } = useStore();

    const [editedWorkItem, setToEditWorkItem] = useState(null);
    const [selectedWeeks, setSelectedWeeks] = useState([INITIAL_VALUE]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedWorkItemStatus, setSelectedWorkItemStatus] = useState([]);
    const [errorFromDate, setErrorFromDate] = useState(false);
    const [errorToDate, setErrorToDate] = useState(false);
    const [selectedScopeType, setSelectedScopeType] = useState();

    const onErrorDates = (type, value) => {
        const setValue = {
            dateFrom: () => setErrorFromDate(value),
            dateTo: () => setErrorToDate(value),
        };

        setValue[type]();
    };

    const filter = useMemo(
        () => ({
            users: selectedUsers.map(item => item.id),
            projects: selectedProjects.map(item => item.id),
            weeks: selectedWeeks.map(item => `${item.week},${item.year}`),
            page,
            per_page: perPage,
            status: selectedWorkItemStatus,
            work_type: selectedScopeType?.value,
            from_date: fromDate?.isValid()
                ? formatToServerString(fromDate)
                : null,
            to_date: toDate?.isValid() ? formatToServerString(toDate) : null,
        }),
        [
            selectedUsers,
            selectedProjects,
            selectedWeeks,
            page,
            perPage,
            selectedWorkItemStatus,
            selectedScopeType,
            fromDate,
            toDate,
        ]
    );

    useEffect(() => {
        const noDateRange = fromDate === null && toDate === null;
        const isValidDateRange = fromDate?.isValid() && toDate?.isValid();
        if (isValidDateRange || noDateRange) {
            onGetLogs(filter);
            onGetTotalLogTime(filter);
        }
    }, [
        filter,
        onGetLogs,
        onGetTotalLogTime,
        errorFromDate,
        errorToDate,
        fromDate,
        toDate,
    ]);

    useEffect(() => {
        if (selectedWeeks.length) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedWeeks, errorFromDate, errorToDate]);

    const onFilterChange = useCallback(
        (type, value) => {
            setInitialPagination();
            const setValueTypes = {
                fromDate: () => {
                    setFromDate(value);
                    setSelectedWeeks([]);
                },
                toDate: () => {
                    setToDate(value);
                    setSelectedWeeks([]);
                },
                selectedWeeks: () => setSelectedWeeks(value),
                selectedProjects: () => setSelectedProjects(value),
                selectedUsers: () => setSelectedUsers(value),
                selectedWorkItemStatus: () => setSelectedWorkItemStatus(value),
                scopeType: () => setSelectedScopeType(value),
            };

            setValueTypes[type]();
        },
        [setInitialPagination]
    );

    useEffect(() => {
        onGetProjectList();
        onGetUserList();
        onGetLogTypes();

        return setEmptyLogs;
    }, [onGetProjectList, onGetUserList, setEmptyLogs, onGetLogTypes]);

    const submitUpdatedLog = async (...param) => {
        await onUpdateLog(...param);
        setToEditWorkItem(null);
    };

    const onDownloadFile = () => {
        onDownload({
            from_date: fromDate ? formatToServerString(fromDate) : null,
            to_date: toDate ? formatToServerString(toDate) : null,
            weeks: selectedWeeks.map(item => `${item.week},${item.year}`),
            users: selectedUsers.map(item => item.id),
            projects: selectedProjects.map(item => item.id),
            status: selectedWorkItemStatus,
        });
    };

    return (
        <Container>
            <FilterSelectorPanel
                fromDate={fromDate}
                toDate={toDate}
                selectedWeeks={selectedWeeks}
                selectedProjects={selectedProjects}
                selectedUsers={selectedUsers}
                setFilter={onFilterChange}
                projectList={projectList}
                selectedWorkItemStatus={selectedWorkItemStatus}
                selectedScopeType={selectedScopeType}
                logs={logs}
                setErrorDates={onErrorDates}
                userList={userList}
            />
            <Box mt={4} mb={5}>
                <Box
                    display="flex"
                    my={3}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {
                        <DownloadButton
                            disabled={logs.length === 0}
                            handleClick={onDownloadFile}
                        >
                            Export
                        </DownloadButton>
                    }
                    <Box display="flex">
                        <TotalTime
                            totalLogTime={totalLogTime}
                            title="Total time:"
                        />
                    </Box>
                </Box>
                <StyledPaper>
                    <LogTableReports
                        logList={logs}
                        onEdit={setToEditWorkItem}
                    />
                    {logs?.length > 0 && <ReportPagination />}
                </StyledPaper>
            </Box>
            <WorkItemAddEditForm
                open={!!editedWorkItem}
                handleClose={() => setToEditWorkItem(null)}
                projects={projectList}
                logTypes={logTypes}
                submitForm={submitUpdatedLog}
                deleteLog={null}
                initialData={editedWorkItem}
                selectedDay={null}
                selectedWeek={null}
            />
        </Container>
    );
}
