import * as jsdiff from "diff";
import React from "react";
import { AddedText, RemovedText } from "./style";

const DiffViewer = ({ old = "", current = "" }) => {
    const diff = jsdiff.diffLines(old || "", current);

    return diff.map(part => {
        return part.added ? (
            <AddedText key={part.value}>{part.value}</AddedText>
        ) : part.removed ? (
            <RemovedText key={part.value}>{part.value}</RemovedText>
        ) : (
            part.value
        );
    });
};

export default DiffViewer;
