// Core
import axios from "axios";

// Constants
import { API_URL } from "./constants";

class LogService {
    getLogs = params => axios.get(`${API_URL}/log`, { params });
    addLog = body => axios.post(`${API_URL}/log`, { ...body });
    updateLog = (params, pathId) =>
        axios.put(`${API_URL}/log/${pathId}`, { ...params });
    deleteLog = id => axios.delete(`${API_URL}/log/${id}`);
    setLogStatus = ({ id, status }) =>
        axios.post(`${API_URL}/log/${id}/${status}`);

    getLogTypes = params => axios.get(`${API_URL}/log-type`, { params });
    updateLogType = (body, typeId) =>
        axios.put(`${API_URL}/log-type/${typeId}`, { ...body });
    addLogType = body => axios.post(`${API_URL}/log-type`, { ...body });
    deleteLogType = id => axios.delete(`${API_URL}/log-type/${id}`);
    getLogHistory = (id, params) =>
        axios.get(`${API_URL}/log/${id}/histories`, { params });
}

const logService = new LogService();

export default logService;
