import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)`
    & .MuiInputBase-root {
        min-height: 40px;
    }
`;

export const Title = styled.span(
    ({
        fontSize,
        theme: {
            palette: {
                default: { main },
            },
        },
        color = main,
    }) => `
  color: ${color};
  line-height: ${fontSize}px;
  font-size: ${fontSize}px;
  overflow: hidden;
  text-overflow: ellipsis;
`
);

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
