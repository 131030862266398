// Core
import React from "react";
import {
    Switch,
    Route,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";

// Components
import NoMatch from "components/NoMatch";
import NavigationPanel from "components/NavigationPanel";

// Routes
import routes from "./routes";

const Navigation = compProps => (
    <Router>
        <NavigationPanel {...compProps} />
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={props => {
                        if (
                            route.adminRedirect &&
                            route.adminRedirect(compProps)
                        ) {
                            return (
                                <Redirect to={{ pathname: "/work-items" }} />
                            );
                        }
                        if (route.devRedirect && route.devRedirect(compProps)) {
                            return (
                                <Redirect
                                    to={{ pathname: "/developer-reports" }}
                                />
                            );
                        }
                        return !route.rules ||
                            route.rules.every(rule => rule(compProps)) ? (
                            <route.component {...props} />
                        ) : (
                            <Redirect to={{ pathname: route.redirect }} />
                        );
                    }}
                />
            ))}

            <Route component={NoMatch} />
        </Switch>
    </Router>
);

export default Navigation;
