// Core
import React, { useState, useEffect } from "react";

// UI
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField } from "./style";
import Box from "@material-ui/core/Box";

import ListOption from "./ListOption";
import CheckboxList from "components/CheckboxList";

const STATUS_OPTIONS = [
    { id: "active", title: "active" },
    { id: "inactive", title: "archived" },
];

function ProjectSelector({ userList, setFilter, selectedUser }) {
    const onChangeUser = (e, value) => {
        setFilter("selectedUsers", value);
    };

    const [selectedStatusId, setSelectedStatusId] = useState([
        STATUS_OPTIONS[0].id,
    ]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const getOptionSelected = (option, value) => option.id === value?.id;
    const getOptionLabel = option => `${option.firstname} ${option.lastname}`;

    useEffect(() => {
        setFilteredUsers(
            userList.filter(project => {
                return selectedStatusId.includes(project.status);
            })
        );
    }, [selectedStatusId, userList]);

    const renderOption = option => <ListOption option={option} />;

    return (
        <Box display="flex" alignItems="flex-end">
            <Box flex={1}>
                <Autocomplete
                    multiple
                    value={selectedUser}
                    options={filteredUsers}
                    onChange={onChangeUser}
                    filterSelectedOptions
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    renderInput={params => (
                        <StyledTextField {...params} label="Team members" />
                    )}
                    renderOption={renderOption}
                />
            </Box>
            <Box width={130}>
                <CheckboxList
                    options={STATUS_OPTIONS}
                    selected={selectedStatusId}
                    onChange={setSelectedStatusId}
                    title={"Status"}
                />
            </Box>
        </Box>
    );
}

export default ProjectSelector;
