import styled from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import LinearProgress from "@material-ui/core/LinearProgress";

export const StyledBackdrop = styled(Backdrop)`
    z-index: ${({ theme }) => theme.zIndex.modal + 1};
`;

export const StyledLinearProgress = styled(LinearProgress)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;
