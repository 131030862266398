// Core
import React from "react";
import DatejsUtils from "@date-io/dayjs";

// UI
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { TIME_FORMAT } from "constants/dateFormats";
import dayjs from "dayjs";
// Constants

export default function Developers({
    value,
    id,
    label,
    onChange,
    error,
    setTouched,
    fullWidth = false,
}) {
    const handleChange = (date, val) => {
        if (!date) {
            onChange(id, "");
            return;
        }
        onChange(id, val);
        setTouched(id);
    };

    return (
        <>
            <MuiPickersUtilsProvider utils={DatejsUtils}>
                <KeyboardTimePicker
                    fullWidth={fullWidth}
                    ampm={false}
                    id={id}
                    error={!!error}
                    label={label}
                    mask="__:__"
                    inputValue={value}
                    format={TIME_FORMAT}
                    onChange={handleChange}
                    helperText={error}
                />
            </MuiPickersUtilsProvider>
        </>
    );
}
