import React from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import dayjs, { formatTimeDuration } from "utils/dateUtils";

// UI
import { Box, Typography, Paper, Tooltip, Grid } from "@material-ui/core";
import UserAvatar from "../../../components/UserAvatar";
import LogStatusHandler from "../LogStatusHandler";

// Constants
import {
    FULL_DATE_FORMAT,
    DATE_FORMAT,
    TIME_FORMAT,
} from "constants/dateFormats";
import { validationErrorMessages, WORK_TYPES } from "constants/developerReport";

// Utils
import { getType } from "utils/managerReportsUtils";
import { getUserAvatar } from "utils/developersUtils";

// Styles
import {
    StyledTextArea,
    StyledTypography,
    StyledColoredName,
    Time,
    StyledBox,
} from "./style";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import Chip from "../../../components/Chip";

let schema = object().shape({
    descriptions: string().required(),
});

const localFixAvatar = avatar => (avatar ? `/uploads${avatar}` : null);
const getWorkTypeLabel = value =>
    value ? WORK_TYPES.find(item => item.value === value) : {};

function InReviewItems({
    onUpdateLog,
    onEdit,
    onUpdateLogStatus,
    logTypes,
    user_id,
    project_id,
    log_type_id,
    date,
    total,
    descriptions,
    status,
    id,
    from_time,
    to_time,
    disabled,
    onShowHistory,
    work_type,
    updated_at,
    created_at,
}) {
    const getLogType = id => {
        return logTypes.find(item => item.id === id)?.type;
    };

    function validate(values) {
        const errors = {};
        if (!values.descriptions) {
            errors.descriptions = validationErrorMessages.descriptions;
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            date,
            descriptions,
            from_time,
            to_time,
            project_id: project_id?.id,
            log_type_id,
            work_type,
        },
        validate,
        validationSchema: schema,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (data, { resetForm }) => {
            if (descriptions === data.descriptions) return;
            onUpdateLog(data, id);
        },
    });

    const onUpdateStatus = async (...param) => {
        const errors = await formik.validateForm();
        if (Object.keys(errors).length) return;

        await formik.submitForm();
        onUpdateLogStatus(...param);
    };

    const workType = getWorkTypeLabel(work_type);

    const ListItem = ({ children, variant = "body1", ...props }) => (
        <StyledTypography variant={variant} component="span" {...props}>
            {children}
        </StyledTypography>
    );

    const Name = ({ children }) => (
        <Typography
            style={{ fontWeight: 800, textAlign: "center" }}
            variant="overline"
        >
            {children}
        </Typography>
    );
    return (
        <StyledBox
            mb={3}
            borderRadius={16}
            overflow="hidden"
            component={Paper}
            variant="outlined"
        >
            <Grid container display="flex">
                <Grid item xs={12} sm={3}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        height="100%"
                        alignItems="center"
                        bgcolor="#f2f2f2"
                    >
                        <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <UserAvatar
                                size={120}
                                firstName={user_id.firstname}
                                lastName={user_id.lastname}
                                avatar={getUserAvatar(
                                    localFixAvatar(user_id.avatar)
                                )}
                            />
                        </Box>

                        <Box py={1}>
                            <Chip type={getType(status)} label={status} />
                        </Box>
                        <Box
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            display="flex"
                            color="white"
                            fontWeight={800}
                            bgcolor="#a8bcd8"
                            px={2}
                        >
                            <Name>
                                {`${user_id.firstname} ${user_id.lastname}`}
                            </Name>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        p={2}
                        mb={2}
                    >
                        <Box display="flex" flexDirection="column" flex={1}>
                            <ListItem>
                                <b>{`Project: `}</b>
                                <StyledColoredName
                                    text={project_id.name}
                                    fontSize={20}
                                    color={project_id.color}
                                />
                            </ListItem>
                            <ListItem>
                                <b>{`Log type: `}</b>
                                {getLogType(log_type_id)}{" "}
                            </ListItem>
                            <ListItem>
                                <b>{`Week: `}</b>
                                {dayjs(date, FULL_DATE_FORMAT).customWeek()}
                            </ListItem>
                            <ListItem>
                                <b>{`Day: `}</b>
                                {dayjs(date, FULL_DATE_FORMAT).format(
                                    DATE_FORMAT
                                )}
                            </ListItem>
                            <ListItem>
                                <b>{`Time spent: `}</b>
                                {formatTimeDuration(total)}
                            </ListItem>
                            <ListItem>
                                <b>{`From `}</b>
                                {from_time}
                                <b>{` to `}</b>
                                {to_time}
                            </ListItem>
                            {workType?.value && (
                                <ListItem
                                    colortype={workType?.color}
                                    variant="body2"
                                >
                                    <b>{getWorkTypeLabel(work_type)?.label}</b>
                                </ListItem>
                            )}
                        </Box>
                    </Box>
                    <Time>
                        <ListItem variant="caption">
                            <b>{`Created at: `}</b>
                            {dayjs(created_at).format(
                                `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                            )}
                        </ListItem>
                        <ListItem variant="caption">
                            <b>{`Updated at: `}</b>
                            {dayjs(updated_at).format(
                                `${FULL_DATE_FORMAT} ${TIME_FORMAT}`
                            )}
                        </ListItem>
                    </Time>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box
                        px={2}
                        pb={2}
                        display="flex"
                        flexDirection="column"
                        minWidth="200px"
                        height="100%"
                    >
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="Edit" placement="top">
                                <IconButton onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="History" placement="top">
                                <IconButton onClick={onShowHistory}>
                                    <AvTimerIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box flex={1}>
                            <StyledTextArea
                                variant="outlined"
                                disabled={disabled}
                                id="descriptions"
                                size="small"
                                onChange={function (e) {
                                    formik.setFieldValue(
                                        "descriptions",
                                        e.currentTarget.value
                                    );
                                }}
                                defaultValue={formik.values.descriptions}
                                error={!!formik.errors.descriptions}
                            />
                        </Box>
                        <Box
                            pt={3}
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            width="100%"
                            flexWrap="wrap"
                        >
                            {!disabled && (
                                <>
                                    <Box>
                                        <LogStatusHandler
                                            id={id}
                                            onUpdateLogStatus={onUpdateStatus}
                                            status={status}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </StyledBox>
    );
}

export default InReviewItems;
