// Core
import React from "react";
//UI
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function UserMultiSelect({ userList, onChange, values, id }) {
    const getOptionLabel = option => `${option.firstname} ${option.lastname}`;
    const handleChange = (e, data) => {
        onChange(id, data);
    };

    const getSelectedOptions = () => {
        return userList.filter(item =>
            values.find(user => user.id === item.id)
        );
    };

    return (
        <>
            <Autocomplete
                multiple
                id={id}
                options={userList}
                getOptionLabel={getOptionLabel}
                value={getSelectedOptions()}
                filterSelectedOptions
                onChange={handleChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Select developers"
                    />
                )}
            />
        </>
    );
}
