// Core
import React from "react";

// UI
import {
    TableHead,
    TableBody,
    Table,
    Box,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import TableRow from "components/TableRow";
import TableCell from "components/TableCell";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// Constants
import { TABLE_HEADERS } from "constants/reportHistory";
import { StyledTableCell } from "components/TableCell/styles";

// Styles
import { StyledContainer } from "./style";

export default function ({
    historyItems,
    userList,
    projectList,
    onDownload,
    deleteHistoryItem,
}) {
    const getDatesLabel = ({ from_date, to_date }) => {
        if (from_date && to_date) {
            return `${from_date} - ${to_date}`;
        }
    };

    const concatUserNames = ({ users }) =>
        userList
            .reduce((acc, item) => {
                if (users?.includes(String(item.id))) {
                    acc.push(`${item.firstname} ${item.lastname}`);
                }
                return acc;
            }, [])
            .join(", ");

    const concatProjectNames = ({ projects }) =>
        projectList
            .reduce((acc, item) => {
                if (projects?.includes(String(item.id))) {
                    acc.push(`${item.name}`);
                }
                return acc;
            }, [])
            .join(", ");

    const onClick = ({ from_date, to_date, week, users, projects }) => {
        onDownload({
            from_date,
            to_date,
            week,
            users,
            projects,
            "export-from-history": true,
        });
    };

    return (
        <StyledContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {TABLE_HEADERS.map((item, i) => (
                            <TableCell key={`${item}-${i}`}>{item}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historyItems?.length ? (
                        historyItems.map(item => (
                            <TableRow hover key={item.id}>
                                <StyledTableCell>{item.id}</StyledTableCell>
                                <StyledTableCell>
                                    {getDatesLabel(item.filter)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {item.filter?.weeks?.join(", ")}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {concatUserNames(item.filter)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {concatProjectNames(item.filter)}
                                </StyledTableCell>
                                <Box
                                    component={StyledTableCell}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                onClick={() =>
                                                    deleteHistoryItem(item.id)
                                                }
                                            >
                                                <DeleteOutlineIcon
                                                    color="secondary"
                                                    size="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box
                                    component={StyledTableCell}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Tooltip title="Download">
                                            <IconButton
                                                onClick={() =>
                                                    onClick(item.filter)
                                                }
                                            >
                                                <GetAppIcon
                                                    color="primary"
                                                    size="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <Box p={3} component="td">
                                {"Empty"}
                            </Box>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </StyledContainer>
    );
}
