import React from "react";
import styled from "styled-components";

import Chip from "@material-ui/core/Chip";

export const StyledChip = styled(({ type, ...restProps }) => (
    <Chip {...restProps} />
))`
    background-color: ${({ type, theme }) => theme.palette[type]?.main};
`;
