import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const StyledProjectName = styled.b`
    color: ${({ color = "#000" }) => color};
`;

export const TypographyBreakWord = styled(Typography)`
    word-break: break-word;
`;
export const CustomBadge = styled.div(
    ({ size, color }) => `
      display: inline-block;
      width: ${size}px;
      height: ${size}px;
      border-radius: 50%;
	  background: ${color};
	  margin: 0 3px;
`
);
