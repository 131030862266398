import styled from "styled-components";

const SIZE = 40;

export const Shape = styled.span`
    ${({ theme }) => `
        width: ${SIZE}px;
    height: ${SIZE / 2}px;
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        background-color: ${theme.palette.logo};
        border-radius: 50%;
        width: 100%;
        height: 200%;
    }
    `}
`;

export const ShapeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${SIZE}px;
    max-height: ${SIZE};
    cursor: pointer;
`;

export const Text = styled.div`
    font-family: "GT-America";
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    padding-left: 11px;
    user-select: none;
    & > span {
        line-height: 0.9em;
        transform: scale(1, 0.9);
        letter-spacing: -0.7px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
`;
