// Core
import React from "react";

// UI
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    FormLabel,
    Typography,
} from "@material-ui/core";

function TypeSelector({ value, projectTypes, onChange, error }) {
    return (
        <RadioGroup label="Type" name="type" value={value} onChange={onChange}>
            <FormLabel error={!!error}>
                <Typography variant="body2">{"Type *"}</Typography>
            </FormLabel>
            <Grid container wrap="nowrap">
                {projectTypes.map(item => (
                    <FormControlLabel
                        key={`project-type-${item}`}
                        value={item}
                        control={<Radio error={error} color={"primary"} />}
                        label={item}
                    />
                ))}
            </Grid>
        </RadioGroup>
    );
}

export default TypeSelector;
