import React from "react";

// UI
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField } from "../UserSelector/style";

// constants
import { WORK_TYPES } from "constants/managerReports";

function ScopeTypeSelector({ setFilter, value = {} }) {
    const changeScopeType = (e, value) => {
        setFilter("scopeType", value);
    };

    const getOptionSelected = (option, value) => option === value;
    const getOptionLabel = option => (option.label ? option.label : "");
    return (
        <Autocomplete
            fullWidth
            value={value}
            options={WORK_TYPES}
            onChange={changeScopeType}
            filterSelectedOptions
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderInput={params => (
                <StyledTextField {...params} label="Scope type" />
            )}
        />
    );
}

export default ScopeTypeSelector;
