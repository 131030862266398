// core
import React from "react";

// UI
import { Box, Typography } from "@material-ui/core";

export default function LogInfoHeader({ workingDay, selectedWeek }) {
    return (
        <Box display="flex" justifyContent="space-between">
            <Box flex={1} display="flex">
                <Typography variant="overline" color="textSecondary" noWrap>
                    <b>Working day:</b>
                </Typography>
                <Box pl={1}>
                    <Typography
                        align="right"
                        variant="subtitle1"
                        gutterBottom
                        color="textPrimary"
                        noWrap
                        paragraph
                    >
                        {workingDay}
                    </Typography>
                </Box>
            </Box>
            <Box flex={1} display="flex" pl={1}>
                <Typography variant="overline" color="textSecondary">
                    <b>Week: </b>
                </Typography>
                <Box pl={1}>
                    <Typography
                        align="right"
                        variant="subtitle1"
                        gutterBottom
                        color="textPrimary"
                        paragraph
                    >
                        {selectedWeek?.week}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
