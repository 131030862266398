// Core
import React from "react";
import dayjs from "dayjs";

import { useFormik } from "formik";

// UI
import { TextField, Grid } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import TimePicker from "components/TimePicker";
import FormDialog from "components/FormDialog";
import DialogActionButton from "components/DialogActionButton";
import Selector from "../Selector";
import LogInfoHeader from "../LogInfoHeader";

// Styles
import useStyles from "./styles";

// Utils
import { getInitialFormValues } from "utils/developerReportUtils";

// Constants
import { validationErrorMessages, WORK_TYPES } from "constants/developerReport";
import { FULL_DATE_FORMAT, TIME_FORMAT } from "constants/dateFormats";

function validateTimeRange(from, to, date) {
    const selectedDate = dayjs(date, FULL_DATE_FORMAT);
    const dateFrom = dayjs(
        `${date}-${from}`,
        `${FULL_DATE_FORMAT}-${TIME_FORMAT}`
    );
    const dateTo = dayjs(`${date}-${to}`, `${FULL_DATE_FORMAT}-${TIME_FORMAT}`);
    return (
        dateFrom.isBefore(dateTo) &&
        !dateFrom.isSame(dateTo) &&
        dateFrom.isSame(selectedDate, "day") &&
        dateTo.isSame(selectedDate, "day")
    );
}

function validate(values) {
    const errors = {};
    if (!validateTimeRange(values.from_time, values.to_time, values.date)) {
        errors.from_time = validationErrorMessages.timeInterval;
        errors.to_time = validationErrorMessages.timeInterval;
    }
    if (!values.project_id) {
        errors.project_id = validationErrorMessages.project;
    }
    if (!values.log_type_id) {
        errors.log_type_id = validationErrorMessages.log;
    }
    if (!values.descriptions) {
        errors.descriptions = validationErrorMessages.descriptions;
    }
    if (!values.work_type) {
        errors.work_type = validationErrorMessages.work_type;
    }
    return errors;
}

export default function WorkItemAddEditForm({
    open,
    handleClose,
    projects,
    logTypes,
    submitForm,
    initialData,
    deleteLog,
    selectedDay,
    selectedWeek,
}) {
    const classes = useStyles();
    const initialValues = getInitialFormValues(initialData);

    if (!initialData) {
        initialValues.date = dayjs(selectedDay).format(FULL_DATE_FORMAT);
    }

    const formik = useFormik({
        initialValues,
        validate,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (data, { resetForm }) => {
            if (data.work_type === "in scope") {
                delete data.work_type;
            }
            submitForm(getInitialFormValues(data), initialData?.id).then(() =>
                resetForm(getInitialFormValues())
            );
        },
    });

    const handleDeleteLog = async () => {
        await deleteLog(initialData.id);
        handleClose();
    };

    const onSetTouched = id => {
        formik.setTouched({
            ...formik.touched,
            [id]: true,
        });
    };

    const workingDay = dayjs(formik.values.date, FULL_DATE_FORMAT).format(
        "DD MMMM"
    );

    const onCancel = () => {
        formik.resetForm(getInitialFormValues());
        handleClose();
    };

    return (
        <FormDialog open={open} handleClose={handleClose} title="Work Item:">
            <LogInfoHeader
                workingDay={workingDay}
                selectedWeek={selectedWeek}
            />
            <form autoComplete="off">
                <Grid container className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <TimePicker
                                fullWidth
                                id="from_time"
                                label="Start time *"
                                error={
                                    formik.touched.to_time &&
                                    formik.touched.from_time &&
                                    formik.errors.from_time
                                }
                                value={formik.values["from_time"]}
                                onChange={formik.setFieldValue}
                                setTouched={onSetTouched}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TimePicker
                                fullWidth
                                margin="normal"
                                id="to_time"
                                error={
                                    formik.touched.to_time &&
                                    formik.touched.from_time &&
                                    formik.errors.to_time
                                }
                                label="End time *"
                                value={formik.values["to_time"]}
                                onChange={formik.setFieldValue}
                                setTouched={onSetTouched}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Selector
                                fullWidth
                                data={logTypes}
                                placeholder="Log type *"
                                labelKey="type"
                                valueKey="id"
                                error={formik.errors.log_type_id}
                                onChange={formik.setFieldValue}
                                id="log_type_id"
                                value={formik.values["log_type_id"]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Selector
                                fullWidth
                                placeholder="Project name *"
                                data={projects}
                                labelKey="name"
                                valueKey="id"
                                error={formik.errors.project_id}
                                onChange={formik.setFieldValue}
                                id="project_id"
                                value={formik.values["project_id"]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Selector
                                fullWidth
                                data={WORK_TYPES}
                                placeholder="Scope type"
                                labelKey="label"
                                valueKey="value"
                                error={formik.errors.work_type}
                                onChange={formik.setFieldValue}
                                id="work_type"
                                value={formik.values["work_type"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="descriptions"
                        error={!!formik.errors.descriptions}
                        fullWidth
                        defaultValue={formik.values.descriptions}
                        onChange={formik.handleChange}
                        label="Description"
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Grid>
                <DialogActions>
                    {initialData && deleteLog && (
                        <DialogActionButton
                            onClick={handleDeleteLog}
                            type="error"
                        >
                            Delete
                        </DialogActionButton>
                    )}
                    <DialogActionButton onClick={onCancel} type="default">
                        Cancel
                    </DialogActionButton>
                    <DialogActionButton
                        onClick={formik.handleSubmit}
                        type="success"
                        variant="contained"
                    >
                        Save
                    </DialogActionButton>
                </DialogActions>
            </form>
        </FormDialog>
    );
}
