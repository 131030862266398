// Pages
import Developers from "../Pages/developers";
import ManagerReports from "../Pages/manager-reports";
import Projects from "../Pages/projects";
import DeveloperReports from "../Pages/developer-reports";
import SignIn from "../Pages/signin";
import Home from "../Pages/home";
import ForgotPassword from "../Pages/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword";
import Activity from "../Pages/Activity";
import LogItems from "../Pages/LogItems";

// Rules
import { authRequired, unauthorizedOnly, roleAdmin, roleDev } from "./rules";

export default [
    {
        path: "/",
        exact: true,
        rules: [authRequired],
        redirect: "/signin",
        adminRedirect: roleAdmin,
        devRedirect: roleDev,
        component: Home,
    },
    {
        path: "/signin",
        exact: true,
        rules: [unauthorizedOnly],
        redirect: "/",
        component: SignIn,
    },
    {
        path: "/forgot-password",
        exact: true,
        rules: [],
        redirect: "/",
        component: ForgotPassword,
    },
    {
        path: "/reset-password",
        exact: true,
        rules: [unauthorizedOnly],
        redirect: "/",
        component: ResetPassword,
    },
    {
        path: "/developer-reports",
        exact: true,
        rules: [authRequired, roleDev],
        redirect: "/",
        component: DeveloperReports,
    },
    {
        path: "/manager-reports",
        exact: true,
        rules: [authRequired, roleAdmin],
        redirect: "/",
        component: ManagerReports,
    },
    {
        path: "/projects",
        exact: true,
        rules: [authRequired, roleAdmin],
        redirect: "/",
        component: Projects,
    },
    {
        path: "/developers",
        exact: true,
        rules: [authRequired, roleAdmin],
        redirect: "/",
        component: Developers,
    },
    {
        path: "/activity",
        exact: true,
        rules: [authRequired],
        redirect: "/",
        component: Activity,
    },
    {
        path: "/work-items",
        exact: true,
        rules: [authRequired, roleAdmin],
        redirect: "/",
        component: LogItems,
    },
];
