import dayjs from "utils/dateUtils";

import { DEFAULT_FORM_VALUES } from "constants/projects";
import { FULL_DATE_FORMAT } from "constants/dateFormats";

export const getInitialFormValues = initialData => {
    if (!initialData) {
        return {
            ...DEFAULT_FORM_VALUES,
        };
    }
    return Object.keys(DEFAULT_FORM_VALUES).reduce((acc, key) => {
        acc[key] = initialData[key];
        return acc;
    }, {});
};

export const getDate = date => dayjs(date).format(FULL_DATE_FORMAT);

export const getTime = date => dayjs(date).format("hh:mm");
