// Core
import React, { useMemo, useState } from "react";

// UI
import { Autocomplete } from "@material-ui/lab";
import { TextField, Box } from "@material-ui/core";

import YearSelector from "./YearSelector";

// Styles
import { StyledSup } from "./style";

// utils
import dayjs, { getWeeksForYears } from "utils/dateUtils";

const getYearInterval = startYear => {
    const currentYear = dayjs().year();
    const nextYear = dayjs().add(1, "year").year();
    const differenceBetweenYear = currentYear - startYear;

    // Generating a sequence of numbers
    const sequencesNumbers = Array.from(
        { length: differenceBetweenYear },
        (v, k) => k
    ).reverse();

    // map our generation of numbers in years
    const years = sequencesNumbers.map(yearIndex =>
        dayjs().subtract(yearIndex, "year").year()
    );

    return [startYear, ...years, nextYear];
};

const CURRENT_YEAR = dayjs().year();
const YEAR_OPTIONS = getYearInterval(dayjs().year(2021).year());

export const INITIAL_VALUE = {
    week: dayjs().customWeek(),
    year: dayjs().endOf("isoWeek").year(),
};

function WeekSelector({ setFilter, selectedWeeks }) {
    const [selectedYears, setYears] = useState([
        YEAR_OPTIONS[YEAR_OPTIONS.length - 1],
    ]);

    const onChangeWeeks = (e, value) => {
        setFilter("selectedWeeks", value);
    };

    const getOptionSelected = (option, value) => {
        return option.week === value.week && option.year === value.year;
    };

    const getOptionLabel = option => {
        return option.week
            ? `${option.week}${
                  CURRENT_YEAR !== option.year ? "," + option.year : ""
              }`
            : "";
    };

    const weekOptions = useMemo(() => {
        return getWeeksForYears(selectedYears.sort());
    }, [selectedYears]);

    return (
        <Box display="flex" alignItems="flex-end">
            <Box flex={1}>
                <Autocomplete
                    multiple
                    value={selectedWeeks}
                    options={weekOptions}
                    groupBy={options => options.year}
                    onChange={onChangeWeeks}
                    filterSelectedOptions
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    renderOption={option => (
                        <p>
                            {option.week}
                            {CURRENT_YEAR !== option.year && (
                                <StyledSup>{option.year}</StyledSup>
                            )}
                        </p>
                    )}
                    renderInput={params => (
                        <TextField {...params} label="Week number" />
                    )}
                />
            </Box>
            <Box width={130}>
                <YearSelector
                    options={YEAR_OPTIONS}
                    selected={selectedYears}
                    onChange={setYears}
                />
            </Box>
        </Box>
    );
}

export default WeekSelector;
