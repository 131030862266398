import React from "react";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import { Title, Wrapper } from "./style";

const ListOption = ({ option }) => {
    return (
        <Wrapper>
            {option.status === "inactive" ? (
                <ArchiveOutlinedIcon color="disabled" size="small" mr={1} />
            ) : null}
            <Title color={option.status === "inactive" ? "lightgray" : ""}>
                {`${option.firstname} ${option.lastname}`}
            </Title>
        </Wrapper>
    );
};

export default ListOption;
