// Core
import React, { useEffect, useState } from "react";
// UI
import { Autocomplete } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
// Components
import ColoredName from "components/ColoredName";
import CheckboxList from "components/CheckboxList";

// Styles
import { StyledTextField, StyledChip } from "./style";

const STATUS_OPTIONS = [
    { id: "active", title: "active" },
    { id: "inactive", title: "archived" },
];

function ProjectSelector({ projects, setFilter, selectedProjects }) {
    const [selectedStatusId, setSelectedStatusId] = useState([
        STATUS_OPTIONS[0].id,
    ]);
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        setFilteredProjects(
            projects.filter(project => {
                return selectedStatusId.includes(project.status);
            })
        );
    }, [projects, selectedStatusId]);

    const onChangeProject = (e, value) => {
        setFilter("selectedProjects", value);
    };

    const getOptionSelected = (option, value) => option.id === value?.id;

    const getOptionLabel = option => option.name;

    return (
        <Box display="flex" alignItems="flex-end">
            <Box flex={1}>
                <Autocomplete
                    multiple
                    renderTags={listProjects =>
                        listProjects.map(project => (
                            <StyledChip
                                key={project.id}
                                label={
                                    <ColoredName
                                        text={project.name}
                                        color={project.color}
                                        fontSize={15}
                                    />
                                }
                                onDelete={e => {
                                    const result = listProjects.filter(
                                        ({ id }) => {
                                            return id !== project.id;
                                        }
                                    );
                                    return onChangeProject(e, result);
                                }}
                            />
                        ))
                    }
                    value={selectedProjects}
                    options={filteredProjects}
                    onChange={onChangeProject}
                    filterSelectedOptions
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    renderInput={params => (
                        <StyledTextField {...params} label="Project name" />
                    )}
                    renderOption={option => (
                        <ColoredName
                            text={option.name}
                            icon={
                                option.status === "inactive" ? (
                                    <ArchiveOutlinedIcon color="disabled" />
                                ) : null
                            }
                            color={
                                option.status === "inactive"
                                    ? "lightgray"
                                    : option.color
                            }
                            fontSize={16}
                        />
                    )}
                />
            </Box>
            <Box width={130}>
                <CheckboxList
                    options={STATUS_OPTIONS}
                    selected={selectedStatusId}
                    onChange={setSelectedStatusId}
                    title={"Status"}
                />
            </Box>
        </Box>
    );
}

export default ProjectSelector;
