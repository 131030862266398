// Core
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledLink = styled(NavLink)`
    ${({ theme }) => `
    text-decoration: none;
    color: ${theme.palette.primary.main};
    &.active {
        color: ${theme.palette.primary.dark};
    }
  `}
`;
