import React from "react";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";

export const StyledAvatar = styled(({ disablePointer, ...restProps }) => (
    <Avatar {...restProps} />
))`
    background-color: ${({ theme }) => theme.palette.logo};
    ${({ disablePointer }) => (!disablePointer ? "cursor: pointer" : "")};
    font-size: ${({ size }) => size / 2}px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;
