// Core
import React from "react";

// UI
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    FormLabel,
    Typography,
} from "@material-ui/core";

function RoleSelector({ value, onChange, id, error }) {
    const handleChange = e => {
        onChange(id, e.target.value);
    };
    return (
        <RadioGroup aria-label="Type" value={value} onChange={handleChange}>
            <FormLabel error={error}>
                <Typography variant="caption">{"Role *"}</Typography>
            </FormLabel>
            <Grid container wrap="nowrap">
                <FormControlLabel
                    value="dev"
                    id={id}
                    control={<Radio color={"primary"} />}
                    label="Developer"
                />
                <FormControlLabel
                    value="admin"
                    id={id}
                    control={<Radio color={"primary"} />}
                    label="Admin"
                />
            </Grid>
        </RadioGroup>
    );
}

export default RoleSelector;
