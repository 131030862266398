import { createGlobalStyle } from "styled-components";

import AmericaMedium from "assets/fonts/GT-America-Extended-Medium.otf";
import AmericaBold from "assets/fonts/GT-America-Extended-Bold.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'GT-America';
        src: local('GT-America'), local('GT-America'),
        url(${AmericaMedium});
        font-weight: 300;
        font-style: normal;
    };
    @font-face {
        font-family: 'GT-America';
        src: local('GT-America'), local('GTAmerica'),
        url(${AmericaBold});
        font-weight: 600;
        font-style: normal;
    }
`;
