// Core
import React, { useMemo } from "react";
// UI
import {
    TableHead,
    TableBody,
    Table,
    Tooltip,
    TableSortLabel,
    Typography,
} from "@material-ui/core";

import TableRow from "components/TableRow";
import TableCell from "components/TableCell";
import ColoredName from "components/ColoredName";

// Constants
import { TABLE_HEADERS, SORT_VALUES } from "constants/developers";
import { formatFromSeconds, getDate } from "utils/developersUtils";

// Styles
import { StyledChip } from "./styles";

export default function ({
    developerList,
    setEditingUserData,
    projectList,
    sort,
    onSort,
}) {
    const getFullName = (firstname, lastname) => `${firstname} ${lastname}`;
    const concatSkills = skillsArray => skillsArray.join(", ");

    const parseProjectsByUser = useMemo(() => {
        return projectList.reduce((acc, project) => {
            project.users.forEach(user => {
                acc[user.id] = acc[user.id]
                    ? [...acc[user.id], project]
                    : [project];
            });

            return acc;
        }, {});
    }, [projectList]);

    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {TABLE_HEADERS.map(({ name, isDisabled }) => (
                        <TableCell key={`${name}`}>
                            <TableSortLabel
                                disabled={isDisabled}
                                active={sort.field === SORT_VALUES[name]}
                                direction={sort.type || "asc"}
                                onClick={onSort(SORT_VALUES[name])}
                            >
                                {name}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {developerList.length ? (
                    developerList.map(developer => (
                        <TableRow
                            type={
                                developer.status === "inactive"
                                    ? "disabled"
                                    : "primary"
                            }
                            hover
                            key={developer.id}
                            onClick={() =>
                                setEditingUserData({
                                    ...developer,
                                    projects:
                                        parseProjectsByUser[developer.id]?.map(
                                            project => project.id
                                        ) || [],
                                })
                            }
                        >
                            <TableCell component="th" scope="row">
                                <Typography variant="caption">
                                    {getDate(developer.created_at)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {getFullName(
                                    developer.firstname,
                                    developer.lastname
                                )}
                            </TableCell>
                            <TableCell>{developer.status}</TableCell>
                            <TableCell>
                                {concatSkills(developer.skills)}
                            </TableCell>
                            <TableCell>
                                {parseProjectsByUser[developer.id]?.map(
                                    ({ alias, color, id, name }) => (
                                        <Tooltip
                                            title={name}
                                            placement="top"
                                            key={id}
                                            arrow
                                        >
                                            <StyledChip
                                                label={
                                                    <ColoredName
                                                        text={alias}
                                                        fontSize={14}
                                                        color={color}
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    )
                                )}
                            </TableCell>
                            <TableCell>
                                {formatFromSeconds(
                                    developer.total_work_time -
                                        developer.approved_total_time
                                )}
                            </TableCell>
                            <TableCell>
                                {formatFromSeconds(
                                    developer.approved_total_time
                                )}
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell>{"Empty"}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
