import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const CheckboxList = ({ selected, options, onChange, title }) => {
    const handleChangeMultiple = e => {
        if (e.target.value.length === 0) return;
        onChange(e.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{title}</InputLabel>
            <Select
                multiple
                value={selected}
                onChange={handleChangeMultiple}
                input={<Input />}
                renderValue={selectedItems => {
                    return options
                        .filter(option => selectedItems.includes(option.id))
                        .map(item => item.title)
                        .join(", ");
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        <Checkbox
                            checked={selected.indexOf(option.id) > -1}
                            name={option.id + ""}
                        />
                        <ListItemText primary={option.title} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CheckboxList;
