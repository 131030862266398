// Core
import React from "react";
import { useStores } from "store";
import { useObserver } from "mobx-react";
// UI
import LogSettingsForm from "./LogSettingsForm";
import FormDialog from "components/FormDialog";
import Close from "@material-ui/icons/Close";

// Styles
import { StyledIconButton } from "./styles";

function useStore() {
    const { logStore } = useStores();

    return useObserver(() => ({
        logTypes: logStore.logTypes,
        onGetLogTypes: logStore.onGetLogTypes,
        onCreateLogType: logStore.onCreateLogType,
        onUpdateLogType: logStore.onUpdateLogType,
        onDeleteLogType: logStore.onDeleteLogType,
    }));
}

export default function Settings({ isOpen, onClose }) {
    const {
        logTypes,
        onCreateLogType,
        onUpdateLogType,
        onDeleteLogType,
    } = useStore();

    const onSubmit = async (type, data, id) => {
        const submit = {
            create: () => onCreateLogType(data),
            update: () => onUpdateLogType(data, id),
            delete: () => onDeleteLogType(id),
        };
        await submit[type]();
    };

    return (
        <FormDialog
            open={isOpen}
            handleClose={onClose}
            title="Add log type"
            scroll="paper"
        >
            <StyledIconButton onClick={onClose} type="primary" size="small">
                <Close />
            </StyledIconButton>
            <LogSettingsForm logTypes={logTypes} onSubmit={onSubmit} />
        </FormDialog>
    );
}
