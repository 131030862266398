import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const YearSelector = ({ selected, options, onChange }) => {
    const handleChangeMultiple = e => {
        if (e.target.value.length === 0) return;
        onChange(e.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
                multiple
                value={selected}
                onChange={handleChangeMultiple}
                input={<Input />}
                renderValue={selected => selected.join(", ")}
            >
                {options.map(year => (
                    <MenuItem key={year} value={year}>
                        <Checkbox
                            checked={selected.indexOf(year) > -1}
                            name={year + ""}
                        />
                        <ListItemText primary={year} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default YearSelector;
