const ROUTES = Object.freeze([
    {
        name: "Reports",
        path: "developer-reports",
    },
    {
        name: "Work items",
        path: "work-items",
    },
    {
        name: "Reports",
        path: "manager-reports",
    },
    {
        name: "Projects",
        path: "projects",
    },
    {
        name: "Team",
        path: "developers",
    },
    {
        name: "Report history",
        path: "report-history",
    },
    {
        name: "History",
        path: "activity",
    },
]);

const ROUTES_FOR_DEV = Object.freeze(["developer-reports", "activity"]);
const ROUTES_FOR_ADMIN = Object.freeze([
    "developers",
    "projects",
    "manager-reports",
    "activity",
    "work-items",
]);

const BY_ROLE = Object.freeze({
    admin: ROUTES_FOR_ADMIN,
    dev: ROUTES_FOR_DEV,
});

export const getRoutesByRole = role => {
    const routesByRole = BY_ROLE[role];

    if (!routesByRole) return [];

    return ROUTES.filter(({ path }) => routesByRole.includes(path));
};
