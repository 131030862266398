// Core
import React from "react";

//styles
import { Shape, ShapeContainer, Text, LogoContainer } from "./styles";

function Logo() {
    return (
        <LogoContainer>
            <ShapeContainer>
                <Shape />
                <Shape />
            </ShapeContainer>
            <Text>
                <span>fountain</span>
                <span>head</span>
                <span>it</span>
            </Text>
        </LogoContainer>
    );
}

export default Logo;
