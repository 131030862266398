import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
    return {
        root: {
            "& .MuiGrid-root": {
                marginBottom: theme.spacing(2),
            },
            "& .MuiFormLabel-root": {
                zIndex: 0,
            },
        },
    };
});

export default useStyles;
