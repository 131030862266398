// Core
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
// Theme
import ThemeProvider from "assets/styles/ThemeProvider";
// Components
import App from "./App";
// Worker
import * as serviceWorker from "./serviceWorker";
// Stores
import RootStore from "./store";

const history = createBrowserHistory();

ReactDOM.render(
    <Provider {...new RootStore()}>
        <Router history={history}>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
