// Core
import React, { useState } from "react";
import { useObserver } from "mobx-react";
import { useFormik } from "formik";
import { useLocation, Redirect } from "react-router-dom";

// Store
import { useStores } from "store";

// UI
import { TextField, Box, Button } from "@material-ui/core";

// Styles
import { StyledContainer } from "./style";

const useStore = () => {
    const { authStore } = useStores();

    return useObserver(() => ({
        onResetPassword: authStore.onResetPassword,
    }));
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {
    const { onResetPassword } = useStore();
    const [toLogin, setToLogin] = useState(false);
    const params = useQuery();

    const formik = useFormik({
        initialValues: {
            token: params.get("token"),
            password: "",
            password_confirmation: "",
        },
        onSubmit: async data => {
            await onResetPassword(data);
            setToLogin(true);
        },
    });
    if (toLogin) {
        return <Redirect to="/signin" />;
    }

    return (
        <>
            <StyledContainer>
                <form>
                    <Box display="flex" flexDirection="column" mb={2}>
                        <Box mb={1}>
                            <TextField
                                id="password"
                                label="Password"
                                fullWidth
                                onChange={formik.handleChange}
                                type="password"
                                defaultValue={formik.values.password}
                            />
                        </Box>
                        <Box mb={1}>
                            <TextField
                                id="password_confirmation"
                                label="Confirm password"
                                fullWidth
                                onChange={formik.handleChange}
                                type="password"
                                defaultValue={formik.values.confirmPassword}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent={"flex-end"} mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </StyledContainer>
        </>
    );
}
