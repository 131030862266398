// Core
import React from "react";

// UI
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// Styles
import { StyledFormControl } from "./styles";

function createOptions(data, opts) {
    return data.map(item => ({
        value: item[opts.value],
        label: item[opts.label],
    }));
}

export default function Selector({
    onChange,
    data,
    name,
    id,
    value,
    labelKey,
    valueKey,
    placeholder,
    error,
    fullWidth = false,
}) {
    const [options] = React.useState(
        createOptions(data, { label: labelKey, value: valueKey })
    );

    const handleChange = e => {
        onChange(id, e.target.value);
    };

    return (
        <StyledFormControl fullWidth={fullWidth}>
            <InputLabel error={Boolean(error)}>{placeholder}</InputLabel>
            <Select value={value || ""} onChange={handleChange}>
                {options.map(item => (
                    <MenuItem key={`${id}-${item.value}`} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
}
