import { IMAGES_URL } from "services/api/constants";
import dayjs from "utils/dateUtils";

import { DEFAULT_FORM_VALUES } from "constants/developers";
import { FULL_DATE_FORMAT } from "constants/dateFormats";

export const getInitialFormValues = initialData => {
    if (!initialData) {
        return {
            ...DEFAULT_FORM_VALUES,
        };
    }
    return Object.keys(DEFAULT_FORM_VALUES).reduce((acc, key) => {
        acc[key] = initialData[key];
        return acc;
    }, {});
};

export const formatFromSeconds = durationInSeconds => {
    const duration = Number(durationInSeconds);

    if (duration === 0) {
        return durationInSeconds;
    }

    const lastSeconds = duration % 3600;

    return `${(duration - lastSeconds) / 3600}h${
        lastSeconds ? ` ${(lastSeconds / 60).toFixed(0)}m` : ""
    }`;
};

export const formatFromSecondsToDecimal = durationInSeconds => {
    const duration = Number(durationInSeconds);

    if (duration === 0) {
        return durationInSeconds;
    }
    return Number((durationInSeconds / 3600).toFixed(2));
};

export const formatFromSecondsToObject = durationInSeconds => {
    const duration = Number(durationInSeconds);

    if (duration === 0) {
        return durationInSeconds;
    }

    const lastSeconds = duration % 3600;

    return {
        h: (duration - lastSeconds) / 3600,
        m: lastSeconds ? (lastSeconds / 60).toFixed(0) : null,
    };
};

export const getUserAvatar = avatar => (avatar ? IMAGES_URL + avatar : null);

export const getDate = date => dayjs(date).format(FULL_DATE_FORMAT);
