// Core
import { observable, action, decorate, runInAction } from "mobx";

// Services
import projectService from "services/api/ProjectService";
import userService from "services/api/UserService";

// Constants
import { MAX_PER_PAGE } from "constants/pagination";

const mapperSortFields = {
    Updated: "updated_at",
    Created: "created_at",
    "Project name": "name",
    Type: "type",
    "Approved time": "approved_time",
};

const fetchStrategy = ({ body, id }) => ({
    delete: () => projectService.deleteProject(id),
    update: () => projectService.updateProject(body, id),
    create: () => projectService.createProject(body),
});

class ProjectStore {
    constructor(rootStore) {
        this.rootStore = rootStore;

        this.projectList = [];
        this.projectTypes = [];
        this.projectUserList = [];
        this.editingProjectData = undefined;
        this.userList = [];
    }

    setPaginationMeta = data => {
        this.rootStore.paginationStore.setMeta(data);
    };

    showNotification = (type, message) => {
        this.rootStore.notificationStore.showNotification({
            message,
            type,
        });
    };

    setLoading(value) {
        this.rootStore.loadersStore.setLoadingPage(value);
    }

    setInitialState = () => {
        this.projectList = [];
        this.projectTypes = [];
    };

    fetch = async (apiCall, ...args) => {
        try {
            this.setLoading(true);
            const response = await apiCall(...args);
            return response?.data?.data;
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    onGetProjectList = async ({ sort, ...params }) => {
        try {
            let enhancedParams = { ...params };
            if (sort?.field) {
                enhancedParams = {
                    ...enhancedParams,
                    sort: { [mapperSortFields[sort.field]]: sort.type },
                };
            }

            this.setLoading(true);
            const response = await projectService.getProjectList(
                enhancedParams
            );
            runInAction("setProjectList", () => {
                this.projectList = response?.data?.data;
            });
            this.setPaginationMeta(response?.data?.meta);
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    handleFetchProjectData = async (actionType, data) => {
        try {
            this.setLoading(true);
            await fetchStrategy(data)[actionType]();
            this.showNotification("success", "Project saved");
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    onCreateProject = body => this.handleFetchProjectData("create", { body });

    onUpdateProject = (body, id) =>
        this.handleFetchProjectData("update", { body, id });

    onDeleteProject = id => this.handleFetchProjectData("delete", { id });

    onGetProjectTypes = async () => {
        try {
            this.setLoading(true);
            const response = await projectService.getProjectTypes();
            runInAction("setProjectTypes", () => {
                this.projectTypes = response?.data?.data;
            });
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    onGetProjectUserList = async id => {
        try {
            this.setLoading(true);
            const response = await projectService.getProjectUserList(id);
            return response?.data?.data;
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    onAddUsersToProject = async (body, id) =>
        this.fetch(projectService.addUsersToProject, body, id);

    onDeleteUsersFromProject = async (body, id) =>
        this.fetch(projectService.deleteUsersFromProject, body, id);

    onGetUserList = async () => {
        try {
            this.setLoading(true);
            const userList = await userService.getUserList({
                per_page: MAX_PER_PAGE,
            });
            runInAction("setUserList", () => {
                this.userList = userList?.data?.data;
            });
        } catch (error) {
            this.showNotification("error", error?.response?.data?.message);
            console.error(error);
        } finally {
            this.setLoading(false);
        }
    };

    setEditingProjectData = async project => {
        if (!project) {
            runInAction(() => {
                this.editingProjectData = undefined;
            });
            return;
        }
        runInAction(() => {
            this.editingProjectData = {
                ...project,
            };
        });
    };
}

decorate(ProjectStore, {
    // state
    projectList: observable,
    projectTypes: observable,
    projectUserList: observable,
    editingProjectData: observable,
    userList: observable,

    //actions
    setInitialState: action,
    onGetProjectList: action,
    onGetUserList: action,
    onGetProjectTypes: action,
    setEditingProjectData: action,
    onCreateProject: action,
    onUpdateProject: action,
    onDeleteProject: action,
    onAddProjectUserList: action,
});

export default ProjectStore;
