// Core
import React from "react";
import { MobXProviderContext } from "mobx-react";

// Stores
import AuthStore from "./AuthStore";
import LogStore from "./LogStore";
import LoadersStore from "./LoadersStore";
import ProjectStore from "./ProjectStore";
import UserStore from "./UserStore";
import NotificationStore from "./NotificationStore";
import ExportStore from "./ExportStore";
import PaginationStore from "./PaginationStore";
import ActivityStore from "./ActivityStore";

class RootStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.logStore = new LogStore(this);
        this.loadersStore = new LoadersStore(this);
        this.projectStore = new ProjectStore(this);
        this.userStore = new UserStore(this);
        this.notificationStore = new NotificationStore(this);
        this.exportStore = new ExportStore(this);
        this.paginationStore = new PaginationStore(this);
        this.activityStore = new ActivityStore(this);
    }
}

// Hook
export const useStores = () => {
    return React.useContext(MobXProviderContext);
};

export default RootStore;
