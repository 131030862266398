export function authRequired({ me }) {
    return !!me;
}

export function unauthorizedOnly(prop) {
    return !prop.me;
}

export function roleAdmin({ me }) {
    return me?.role === "admin";
}

export function roleDev({ me }) {
    return me?.role === "dev";
}
