import styled from "styled-components";
import { Popper, Button } from "@material-ui/core";
export const StyledPopper = styled(Popper)`
    z-index: 1;
`;

export const StyledButton = styled(Button)`
    background-color: ${({ type, theme }) => theme.palette[type]?.main};
    min-width: 90px;
    min-height: 30px;
    &:hover {
        background-color: ${({ type, theme }) => theme.palette[type]?.light};
    }
`;
