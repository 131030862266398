// Core
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react";
import dayjs from "utils/dateUtils";
// UI
import { Container, Grid, Box } from "@material-ui/core";
import AddButton from "components/AddButton";
import WeekDates from "components/WeekDates";
import ReportTable from "Pages/developer-reports/ReportTable";
import WorkItemAddEditForm from "components/WorkItemAddEditForm";
import TotalTimeInfo from "./TotalTimeInfo";
import WeekSelector, { INITIAL_VALUE } from "./WeekSelector";

// Utils
import { useStores } from "../../store";
import { getParametersForLogs } from "utils/developerReportUtils";

function useStore() {
    const { logStore, notificationStore } = useStores();

    return useObserver(() => ({
        logs: logStore.logs,
        declinedLogs: logStore.declinedLogs,
        logTypes: logStore.logTypes,
        projectList: logStore.projectList,
        onGetLogs: logStore.onGetLogs,
        onGetDeclinedLogs: logStore.onGetDeclinedLogs,
        onGetLogTypes: logStore.onGetLogTypes,
        onGetProjectList: logStore.onGetProjectList,
        onAddLog: logStore.onAddLog,
        onUpdateLog: logStore.onUpdateLog,
        onDeleteLog: logStore.onDeleteLog,
        setEmptyLogs: logStore.setEmptyLogs,
        showNotification: notificationStore.showNotification,
    }));
}

export default function Developers() {
    const {
        logs,
        declinedLogs,
        logTypes,
        projectList,
        onGetLogs,
        onGetDeclinedLogs,
        onGetProjectList,
        onGetLogTypes,
        onAddLog,
        onUpdateLog,
        onDeleteLog,
        setEmptyLogs,
        showNotification,
    } = useStore();

    const [selectedWeek, setWeek] = useState(INITIAL_VALUE);
    const [selectedDay, setDay] = useState(dayjs());
    const [isModalVisible, setModalVisibility] = useState(false);
    const [editingLog, setEditingLog] = useState();
    const [totalWeekTime, setTotalWeekTime] = useState();
    const [totalDayTime, setTotalDayTime] = useState();

    useEffect(() => {
        if (!selectedWeek) {
            setEmptyLogs();
            return;
        }
        onGetLogs({
            weeks: [`${selectedWeek.week},${selectedWeek.year}`],
            per_page: 1000,
        });
        onGetDeclinedLogs({
            weeks: [`${selectedWeek.week},${selectedWeek.year}`],
            per_page: 1000,
        });
    }, [onGetLogs, onGetDeclinedLogs, selectedWeek, setEmptyLogs]);

    useEffect(() => {
        onGetProjectList();
        onGetLogTypes();
    }, [onGetLogTypes, onGetProjectList]);

    const showModal = () => {
        if (!selectedDay || !selectedWeek.week) {
            showNotification({
                type: "info",
                message: "Please, choose the weekday first.",
            });
            setEditingLog(null);
            return;
        }
        setModalVisibility(true);
    };

    const closeModal = () => {
        setModalVisibility(false);
        setEditingLog(null);
    };

    const handleEditLog = log => {
        setEditingLog(log);
        showModal();
    };

    const handleSubmitForm = async (data, id) => {
        editingLog ? await onUpdateLog(data, id) : await onAddLog(data);
        closeModal();
    };

    const handleDeleteLog = async id => {
        await onDeleteLog(id);
        onGetLogs({
            ...getParametersForLogs(selectedWeek),
            per_page: 1000,
        });
        closeModal();
    };

    return (
        <Container>
            <Grid container wrap="wrap">
                <Box width="10%" m={2} minWidth={120}>
                    <WeekSelector
                        setWeek={setWeek}
                        setDay={setDay}
                        selectedWeek={selectedWeek}
                    />
                </Box>
                <Box m={2}>
                    <WeekDates
                        selectedWeek={selectedWeek}
                        selectedDay={selectedDay}
                        setDay={setDay}
                        logs={logs}
                        declinedLogs={declinedLogs}
                    />
                </Box>
                <Box
                    m={2}
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    flexWrap="wrap"
                >
                    <Box>
                        <AddButton handleClick={showModal}>
                            Add work item
                        </AddButton>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                    >
                        <TotalTimeInfo
                            totalDayTime={totalDayTime}
                            totalWeekTime={totalWeekTime}
                            selectedWeek={selectedWeek}
                        />
                    </Box>
                </Box>
                <Grid item xs={12}></Grid>
                <ReportTable
                    logList={logs}
                    projects={projectList}
                    logTypes={logTypes}
                    handleEditLog={handleEditLog}
                    selectedDay={selectedDay}
                    selectedWeek={selectedWeek}
                    setTotalWeekTime={setTotalWeekTime}
                    setTotalDayTime={setTotalDayTime}
                />
                <WorkItemAddEditForm
                    open={isModalVisible}
                    handleClose={closeModal}
                    projects={projectList}
                    logTypes={logTypes}
                    submitForm={handleSubmitForm}
                    deleteLog={handleDeleteLog}
                    initialData={editingLog}
                    selectedDay={selectedDay}
                    selectedWeek={selectedWeek}
                />
            </Grid>
        </Container>
    );
}
