export const TABLE_HEADERS = [
    { name: "Joined", isDisabled: false },
    { name: "Name", isDisabled: false },
    { name: "Status", isDisabled: false },
    { name: "Skills", isDisabled: true },
    { name: "Project name", isDisabled: true },
    { name: "Unapproved time", isDisabled: true },
    { name: "Approved time", isDisabled: false },
];

export const SORT_VALUES = {
    Joined: "created_at",
    Name: "firstname",
    Status: "status",
    "Approved time": "approved_total_time",
};

export const DEFAULT_FORM_VALUES = {
    firstname: "",
    lastname: "",
    skills: [],
    email: "",
    role: "dev",
    rate: 0,
    country: "",
    projects: [],
};
