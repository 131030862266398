import dayjs from "utils/dateUtils";
import { TIME_FORMAT, MONDAY, FULL_DATE_SERVER } from "constants/dateFormats";

export const getInitialFormValues = initialData => ({
    date: initialData
        ? dayjs(initialData.date, FULL_DATE_SERVER).format(FULL_DATE_SERVER)
        : "",
    descriptions: initialData ? initialData.descriptions : "",
    from_time: initialData
        ? dayjs(initialData["from_time"], TIME_FORMAT).format(TIME_FORMAT)
        : dayjs("00:00", TIME_FORMAT).format(TIME_FORMAT),
    to_time: initialData
        ? dayjs(initialData["to_time"], TIME_FORMAT).format(TIME_FORMAT)
        : dayjs("00:00", TIME_FORMAT).format(TIME_FORMAT),
    project_id: initialData ? initialData["project_id"] : 0,
    log_type_id: initialData ? initialData["log_type_id"] : 0,
    work_type: initialData ? initialData["work_type"] : "",
});

export const getTotalTimeObject = durations => {
    if (!durations.length) return;
    const totalDurations = durations.reduce(
        (prev, cur) => {
            return prev.clone().add(
                dayjs.duration({
                    hours: cur.total.split(":")[0],
                    minutes: cur.total.split(":")[1],
                })
            );
        },
        dayjs.duration({
            hours: 0,
            minutes: 0,
        })
    );

    const hours = totalDurations.clone().hours();
    const minutes = totalDurations.clone().minutes();

    if (!hours) {
        return {
            minutes,
            decimal: totalDurations.clone().asHours().toFixed(2),
        };
    }
    return {
        h: hours,
        m: minutes,
        decimal: totalDurations.clone().asHours().toFixed(2),
    };
};

export const getParametersForLogs = selectedWeek => ({
    from_date: dayjs()
        .customWeek(selectedWeek)
        .day(MONDAY)
        .format(FULL_DATE_SERVER),
    to_date: dayjs()
        .customWeek(selectedWeek)
        .endOf("isoWeek")
        .format(FULL_DATE_SERVER),
});

export const getMonthNameByWeek = date =>
    dayjs()
        .year(date.year)
        .startOf("year")
        .customWeek(date.week, date.year)
        .format("MMMM");
