// Core
import React from "react";
import { useObserver } from "mobx-react";

// Styles
import { StyledBackdrop, StyledLinearProgress } from "./styles";

// utils
import { useStores } from "../../store";

// Hooks
function useLoadingData() {
    const { loadersStore } = useStores();

    return useObserver(() => ({
        isLoadingPage: loadersStore.isLoadingPage,
    }));
}

const PageLoader = () => {
    const { isLoadingPage } = useLoadingData();

    if (!isLoadingPage) return null;

    return (
        <StyledBackdrop open={isLoadingPage}>
            <StyledLinearProgress color="secondary" />
        </StyledBackdrop>
    );
};

export default PageLoader;
