// Core
import React from "react";
import clsx from "clsx";

// UI
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

// Styles
import { StyledLink } from "./styles";

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    menuButton: {
        color: "#fff",
    },
    active: {
        background: "red",
    },
    pointer: {
        cursor: "pointer",
    },
}));

const NavigationMenu = ({ menuItems, me, onLogout }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = anchor => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {menuItems.map(({ path, name }) => (
                    <StyledLink to={`/${path}`} key={path}>
                        <ListItem button>
                            <ListItemText primary={name} />
                        </ListItem>
                    </StyledLink>
                ))}
            </List>
            <Divider />
        </div>
    );

    return (
        <div>
            <IconButton
                aria-label="primary"
                className={classes.menuButton}
                onClick={toggleDrawer("left", true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="right"
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
            >
                {list("left")}
                {me && (
                    <ListItem divider>
                        <ListItemText
                            className={classes.pointer}
                            onClick={onLogout}
                            secondary="Sign out"
                        />
                    </ListItem>
                )}
            </Drawer>
        </div>
    );
};

export default NavigationMenu;
