// Core
import React from "react";

// UI
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Styles
import { StyledDialog } from "./styles";

export default function FormDialog({
    open,
    handleClose,
    title,
    children,
    className,
}) {
    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"lg"}
            className={className}
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
        </StyledDialog>
    );
}
