import styled from "styled-components";

export const StyledSup = styled.sup`
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledContainer = styled.div`
    tr > th:last-child,
    tr > td:last-child,
    tr > td:nth-last-child(2),
    tr > th:nth-last-child(2) {
        width: 60px;
        text-align: center;
    }
`;
