import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const StyledValue = styled(Typography)`
    font-weight: 800;
    margin-right: 2px;
`;

const StyledUnit = styled(Typography)`
    margin-right: 3px;
`;

function Time({ value, unit }) {
    if (!value && !unit) return 0;
    return (
        value > 0 && (
            <>
                <StyledValue
                    align="right"
                    variant="subtitle1"
                    gutterBottom
                    color="textPrimary"
                >
                    {value}
                </StyledValue>
                <StyledUnit
                    align="right"
                    variant="subtitle1"
                    gutterBottom
                    color="textPrimary"
                >
                    {unit}
                </StyledUnit>
            </>
        )
    );
}

export default Time;
